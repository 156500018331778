@charset "UTF-8";

/*========= レイアウトのためのCSS ===============*/

.wave-wrapper {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  @include respond(sp) {
    // @include max('top', calc(10 + 1vw));
    top: 2%;
    // height: 30px;
    // @include max('height', 30);
    height: 110px;
  }
  @include respond(tab-port) {
    top: 9rem;
    height: 110px;
  }
}
/*========= waveを描画するエリア設定 ===============*/

.wave {
  position: relative;
  @include respond(sp) {
    // height: 30px;
    // @include max('height', 30);
    height: 110px;
  }
  @include respond(tab-port) {
    height: 110px;
  }
}

.wave canvas {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  @include respond(sp) {
    // height: 30px;
    // @include max('height', 30);
    height: 110px;
  }
  @include respond(tab-port) {
    height: 110px;
  }
}

@charset "UTF-8";
.menu {
  cursor: pointer;
  background-color: $color-primary;
  @include respond(sp) {
    width: 6rem;
    height: 6rem;
    padding: 1.8rem 1.5rem 2.3rem;
  }
  @include respond(tab-port) {
    padding: 3rem 2rem;
    width: 8rem;
    height: 8rem;
  }

  @include respond(menu-break) {
    display: none;
  }
  &__wrapper {
    pointer-events: none;
    position: relative;
  }
  & span {
    pointer-events: none;
    display: block;
    width: 30px;
    height: 2px;
    background-color: $color-menu-white;
    position: absolute;
    left: 0;
    transition: 0.5s ease;
    @include respond(tab-port) {
      width: 40px;
    }
    &:first-child {
      top: 0;
    }
    &:nth-child(2) {
      top: 10px;
    }
    &:nth-child(3) {
      top: 20px;
    }
  }
  &.open {
    & span:first-child {
      @include respond(sp) {
        transform: translate(2px, 10px) rotate(45deg);
      }
      @include respond(tab-port) {
        transform: translate(0px, 10px) rotate(45deg);
      }
    }
    & span:nth-child(2) {
      transform: scaleX(0);
    }
    & span:nth-child(3) {
      @include respond(sp) {
        transform: translate(2px, -10px) rotate(-45deg);
      }
      @include respond(tab-port) {
        transform: translate(0px, -10px) rotate(-45deg);
      }
    }
  }
}

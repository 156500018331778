@charset "UTF-8";
.section.order {
  background-color: $color-menu-white;

  @include respond(sp) {
    @include max("padding-top", 40);
    @include max("padding-bottom", 50);
  }
  @include respond(tab-port) {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }

  // section override
  & .section {
    &__title {
      @include respond(sp) {
        @include max('margin-bottom', 20);
      }
      @include respond(tab-port) {
        margin-bottom: 5rem;
      }
    }
    &__image {
      @include respond(sp) {
        display: none;
      }
    }

    &__text {
      @include respond(sp) {
        @include max("margin-bottom", 10);
      }
      @include respond(tab-port) {
        margin-bottom: 2rem;
      }
    }

    &__box {
      @include respond(tab-port) {
      }

      & .section__col2 {
        @include respond(sp) {
          @include max("margin-bottom", 40);
        }
        @include respond(tab-port) {
          margin-bottom: 3rem;

        }
      }
    }
    &__link {
      &.main {
        @include respond(tab-port) {
        }
      }
    }
  }

  // ORDER
  & .order {
    &__image {
      position: relative;
      @include respond(sp) {
        // @include max("width", 260);
        // @include max("height", 200);
        & img {
          @include max("width", 260);
          @include max("height", 200);
          @include max("border-radius", 10);
          object-fit: cover;
          display: block;
        }
      }
      @include respond(tab-port) {
        & img {
          border-radius: 1rem;
          width: 100%;
          height: auto;
          object-fit: cover;
          display: block;
        }
      }
    }

    &__nums {
      position: absolute;
      color: $color-menu-white;
      @include respond(sp) {
        @include max("font-size", 40);
        @include max("top", 10);
        @include max("left", 10);
        line-height: 1.75;
        letter-spacing: 0.04em;
      }
      @include respond(tab-port) {
        font-size: 4rem;

        top: 1rem;
        left: 2rem;
      }
    }
  }
}

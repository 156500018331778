@charset "UTF-8";
.section {
  width: 100%;
  @include respond(sp) {
    @include max("padding-left", 30);
    @include max("padding-right", 30);
  }
  @include respond(tab-port) {
    padding-left: 8rem;
    padding-right: 8rem;
  }
  &__wrapper {
    @include respond(tab-port) {
      max-width: 104rem;
      margin: 0 auto;
    }
  }

  // パンくず
  &__breadcrumb {
    @include respond(sp) {
      @include max("margin-top", 10);
      @include max("margin-bottom", 30);
    }
    @include respond(tab-port) {
      margin-top: 2rem;
      margin-bottom: 3.2rem;
    }
    & span,
    & a {
      display: inline-block;
      @include respond(sp) {
        @include max("font-size", 10);
        letter-spacing: 0;
        line-height: 1.5;
        font-family: $font-noto;
      }
      @include respond(tab-port) {
        font-size: 1.3rem;
        letter-spacing: -0.02em;
        line-height: 1.5384;
      }
    }

    & a {
      color: $color-primary;
      @include hover;
      @include respond(sp) {
        @include max("margin-right", 6);
        display: none;
        &:first-child {
          display: inline-block;
        }
      }
      @include respond(tab-port) {
        margin-right: 2rem;
      }
      
    }
    & .b-arrow {
      color: $color-black;
      display: inline-block;
      @include respond(sp) {
        @include max("margin-right", 10);
      }
      @include respond(tab-port) {
        margin-right: 2rem;
      }
    }
  }

  // レイアウト
  &__box {
    @include respond(tab-port) {
      display: flex;
    }
    &.col3 {
      justify-content: space-between;
      @include pcmin("gap", 55);
      & .section {
        &__col1,
        &__col2,
        &__col3 {
          flex-basis: 33%;
        }
      }
    }
  }
  &__col1 {
    position: relative;
    @include respond(tab-port) {
      flex-basis: 50%;
    }
  }
  &__col2 {
    position: relative;
    @include respond(tab-port) {
      flex-basis: 50%;
    }
  }

  // TITLE
  &__title {
    @include respond(sp) {
      @include max("font-size", 28);
      line-height: 1.5;
      letter-spacing: 0.05em;
      @include max("padding-top", 7);
      @include max("padding-bottom", 7);
    }
    @include respond(tab-port) {
      font-size: 4.2rem;
      line-height: 1.6666;
      letter-spacing: 0.05em;
      padding: 14px 0;
      &.secondary {
        font-size: 2.8rem;
        line-height: 1.5;
        padding: 7px 0;
      }
    }
    // underline
    &.underline {
      position: relative;
      @include respond(sp) {
        @include max("padding-bottom", 17);
        @include max("margin-bottom", 20);
      }
      @include respond(tab-port) {
        padding: 15px 0;
        margin-bottom: 5rem;
        width: max-content;
        font-size: 5rem;
        line-height: 1.6;
      }
      &::before {
        content: "";
        position: absolute;
        left: 0;
        background-color: $color-primary-light;
        height: 1px;
        @include respond(sp) {
          // @include max("top", 28);
          bottom: 0;
          @include max("width", 50);
          // width: 5rem;
        }
        @include respond(tab-port) {
          bottom: -2rem;
          width: 5rem;
        }
      }
    }
  }

  // H3
  &__h3 {
    @include respond(sp) {
      @include max("font-size", 18);
      line-height: 1.7777;
      letter-spacing: 0.07em;
      @include max("padding-top", 5);
      @include max("padding-bottom", 5);
    }
    @include respond(tab-port) {
      margin-top: 2rem;
      font-size: 2.8rem;
      line-height: 1.5;
      letter-spacing: 0.05em;
      padding: 7px 0;
    }
  }

  // H4
  &__h4 {
    @include respond(sp) {
      @include max("font-size", 16);
      line-height: 2;
      letter-spacing: 0.07em;
    }
    @include respond(tab-port) {
      font-size: 1.8rem;
      line-height: 1.777;
      letter-spacing: 0.07em;
    }
  }
  // IMAGE
  &__image {
    @include respond(sp) {
      width: 100%;
      height: auto;
    }

    @include respond(tab-port) {
      width: 100%;
    }

    & img {
      object-fit: cover;
      display: block;
      @include respond(sp) {
        width: 100%;
        @include max("height", 175);
      }

      @include respond(tab-port) {
        width: 100%;
        height: auto;
      }
    }
  }

  // 英字
  &__en {
    line-height: 1.1428;
    letter-spacing: 0.04em;
    color: $color-primary;
    @include respond(sp) {
      @include max("font-size", 14);
      line-height: 1.1428;
      letter-spacing: 0.04em;
    }
    @include respond(tab-port) {
      font-size: 1.4rem;
    }

    // en title
    &.title {
      position: relative;
      @include respond(sp) {
        @include max("padding-bottom", 20);
      }
      @include respond(tab-port) {
        padding-bottom: 3rem;
      }
      &::before {
        content: "";
        position: absolute;
        left: 0;
        background-color: $color-primary;
        height: 1px;
        @include respond(sp) {
          // @include max("top", 28);
          bottom: 0;
          @include max("width", 50);
          // width: 5rem;
        }
        @include respond(tab-port) {
          bottom: 0;
          width: 5rem;
        }
      }
      &.u-text-center {
        &::before {
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }

  // TEXT
  &__text {
    font-family: $font-noto;
    @include respond(sp) {
      @include max("font-size", 14);
      @include max("padding-top", 5);
      @include max("padding-bottom", 5);
      line-height: 1.7857;
      letter-spacing: 0;
      text-align: justify;
    }

    @include respond(tab-port) {
      font-size: 1.6rem;
      line-height: 1.875;
      letter-spacing: 0.03em;
      padding: 7px 0;
    }
    &.lg {
      @include respond(sp) {
        @include max("font-size", 16);
        line-height: 1.857;
        letter-spacing: 0.03em;
      }

      @include respond(tab-port) {
        font-size: 1.8rem;
        line-height: 2.7777;
        letter-spacing: 0.07em;
      }
    }
  }

  // LINK
  &__link {
    width: max-content;
    display: flex;
    align-items: center;
    transition: 0.3s ease;
    @include respond(sp) {
      @include max("font-size", 12);
      line-height: 1.416;
      letter-spacing: 0;
      & .arrow {
        @include max("margin-right", 10);
        &.circle {
          @include max("margin-left", 18);
          @include max("margin-right", 28);
          @include max("height", 50);
        }
      }
    }

    @include respond(tab-port) {
      font-size: 1.4rem;
      line-height: 1.428;
      letter-spacing: 0;
      & .arrow {
        margin-right: 2rem;
        &.circle {
          height: 8rem;
          margin-right: 4.3rem;
          margin-left: 3rem;
        }
      }
    }

    // link main
    &.main {
      position: relative;
      & a {
        @include absolute(10);
        @include respond(hover) {
          &:hover {
            & ~ .arrow {
              transform: translateX(1rem);
            }
            & ~ .circle::before {
              transform: translate(calc(-50% - 1rem), -50%) scale(0.75);
            }
            & ~ .circle ~ span {
              transform: translateX(0.5rem);
            }
          }
        }
      }
    }

    // link-half
    &-half {
      position: relative;
      background-color: $color-white;
      transition: 0.3s ease;
      & a {
        @include absolute(10);
      }
      & .arrow {
        margin-bottom: auto;
        margin-top: auto;
      }

      @include respond(sp) {
      }
      @include respond(tab-port) {
        padding: 3rem 6rem;
        @include mv("padding-right", 60);
        border-radius: 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      @include respond(hover) {
        &:hover {
            background-color: $color-hover-gray;
            & .arrow {
              transform: translateX(1rem);
            }
        }
      }
      &__text {
        @include respond(sp) {
        }
        @include respond(tab-port) {
          font-size: 1.8rem;
          letter-spacing: 0.07em;
          line-height: 1.7777;
          margin-bottom: 1.5rem;
        }
      }
    }
  }

  // 見積もりや問い合わせなど
  &__cta {
    position: relative;
    background-color: $color-secondary;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: 0.3s ease;
    @include respond(sp) {
      &:not(:first-child) {
        @include max("margin-top", 30);
      }
    }
    @include respond(tab-port) {
      &:not(:first-child) {
        margin-top: 3rem;
      }
    }
    @include respond(hover) {
      &:hover {
          background-color: $color-hover-primary;
          & .arrow {
            transform: translateX(1rem);
          }
      }
    }
    &.gray {
      background-color: $color-white;
      @include respond(hover) {
        &:hover {
            background-color: $color-hover-gray;
            & .arrow {
              transform: translateX(1rem);
            }
        }
      }
    }
    &.estimate {
      @include respond(sp) {
        justify-content: space-between;
        @include max("border-radius", 10);
        @include mp("padding", 30, 26, 30, 26);
        @include max("row-gap", 15);
      }
      @include respond(tab-port) {
        max-height: 17rem;
        border-radius: 1rem;
        @include pcmp("padding", 50, 60, 50, 60);
        flex-direction: row;
        // align-items: flex-start;
      }
      & .section__cta-icon {
        background: url("../images/common/estimate.svg") no-repeat;
        background-size: 100%;
        background-position: center;
        @include respond(sp) {
          @include max("width", 40);
          @include max("height", 56);
        }
        @include respond(tab-port) {
          width: 5rem;
          height: 7rem;
          margin-right: 2rem;
        }
      }
    }
    &.question {
      @include respond(sp) {
        justify-content: space-between;
        @include max("border-radius", 10);
        @include mp("padding", 16, 26, 30, 26);
      }
      @include respond(tab-port) {
        border-radius: 1rem;
        @include pcmp("padding", 29, 60, 26, 66);
        flex-direction: row;
        // align-items: flex-start;
      }
      & .section__cta-icon {
        background: url("../images/common/question.svg") no-repeat;
        // background-size: 100%;
        background-size: 75%;
        background-position: center;
        @include respond(sp) {
        background-size: 75%;
          @include max("width", 31);
          @include max("height", 70);
          @include max("margin-bottom", 5);
          @include min('margin-top', -5);
        }
        @include respond(tab-port) {
          width: 4.4rem;
          height: 11.5rem;
          min-width: 4.4rem;
          min-height: 11.5rem;
          margin-right: 2rem;
        }
      }
    }
    &.email {
      @include respond(sp) {
        justify-content: space-between;
        @include max("border-radius", 10);
        @include mp("padding", 30, 26, 30, 26);
        @include max("row-gap", 15);
      }
      @include respond(tab-port) {
        max-height: 17rem;
        border-radius: 1rem;
        @include pcmp("padding", 50, 60, 50, 60);
        flex-direction: row;
        // align-items: flex-start;
      }
      & .section__cta-icon {
        background: url("../images/common/email.svg") no-repeat;
        background-size: 100%;
        background-position: center;
        @include respond(sp) {
          @include max("width", 50);
          @include max("height", 40);
          align-self: baseline;
        }
        @include respond(tab-port) {
          width: 5rem;
          height: 7rem;
          margin-right: 2rem;
        }
      }
    }
    &-icon {
      display: inline-block;
    }
    &-box {
      width: 100%;
      display: flex;
      align-items: center;
      @include respond(sp) {
        justify-content: space-between;
      }
      @include respond(tab-port) {
        max-width: 22rem;
        // margin-right: 6rem;
        @include mv("margin-right", 60);
      }
    }

    &-title {
      white-space: nowrap;
      @include respond(sp) {
        @include max("font-size", 18);
        @include max("margin-right", 5);
        line-height: 1.3888;
        letter-spacing: 0.07em;
      }
      @include respond(tab-port) {
        font-size: 2.5rem;
        letter-spacing: 0;
        line-height: 1.2;
      }
    }
    &-text {
      @include respond(sp) {
        @include max("font-size", 13);
        line-height: 1.384;
        letter-spacing: 0.05em;
      }
      @include respond(tab-port) {
        font-size: 1.6rem;
        line-height: 1.875;
        letter-spacing: 0.03em;
        justify-self: flex-start;
        max-width: 53rem;
        @include mv("margin-right", 96);
      }
    }
    &-link {
      @include absolute(10);
    }
    & .arrow {
      display: block;
      align-self: center;
      @include respond(sp) {
        justify-self: flex-end;
      }
      @include respond(tab-port) {
        min-width: 14px;
        margin-left: auto;
      }
    }
  }

  // SECTION NAV
  &__nav {
    @include respond(sp) {
      @include max("margin-top", 30);
    }
    @include respond(tab-port) {
      max-width: 38rem;
      margin-left: auto;
      margin-right: 0;
      @include mv("padding-left", 20);
    }
    &-list {
      @include respond(sp) {
      }
      @include respond(tab-port) {
      }
    }
    &-item {
      position: relative;
      display: flex;
      align-items: center;
      &:first-of-type {
        border-top: 1px solid $color-secondary;
      }
      border-bottom: 1px solid $color-secondary;

      @include respond(sp) {
        @include max("padding-top", 20);
        @include max("padding-bottom", 20);
        @include max("padding-left", 10);
      }
      @include respond(tab-port) {
        padding: 2.5rem 0 2.5rem 1.6rem;
      }
      @include respond(hover) {
        &:hover {
          & .arrow {
            transform: translateX(1rem);
          }
          & .arrow.down {
            transform: rotate(90deg) translateX(0.5rem);
          }
        }
      }
      & a {
        @include absolute(10);
      }
      & .arrow {
        flex-shrink: 0;
        align-self: unset;
      }
      & .num {
        display: inline-block;
        width: min-content;
        white-space: nowrap;
        @include respond(sp) {
          display: none;
        }
        @include respond(tab-port) {
          @include mv("margin-right", 13);
          @include mv("margin-left", 35);
          font-size: 2rem;
          line-height: 1.15;
          letter-spacing: 0.04em;
        }
      }
      & p {
        @include respond(sp) {
          @include max("margin-left", 20);
          @include max("font-size", 14);
          line-height: 1.428;
          letter-spacing: 0;
        }

        @include respond(tab-port) {
          font-size: 1.8rem;
          letter-spacing: 0.07em;
          line-height: 1.7777;
          white-space: nowrap;
        }
        @include respond(min-tab-land) {
          white-space: unset;
        }
      }

      &.active {
        background-color: $color-white;
        color: $color-primary-light;
      }
    }
  }
}

@charset "UTF-8";
.section.about {
  background-color: $color-white;
  padding-left: 0;
  padding-right: 0;
  position: relative;
  @include respond(sp) {
    @include max("padding-top", 50);
    @include max("padding-bottom", 40);
  }
  @include respond(tab-port) {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }

  // waveの場所設定
  & .wave-wrapper {
    top: 30rem;
    @include respond(sp) {
      &,
      & .wave,
      & canvas {
        display: none;
      }
    }
  }

  // section override
  & .section {
    &__wrapper {
      @include respond(sp) {
        @include max("padding-left", 30);
        @include max("padding-right", 30);
      }
      @include respond(tab-port) {
        padding-left: 8rem;
        padding-right: 8rem;
        max-width: 120rem;
      }
    }
    &__title {
      @include respond(sp) {
        @include max('margin-bottom', 20);
      }
      @include respond(tab-port) {
      }
    }

    &__text {
      @include respond(sp) {
        @include max("margin-bottom", 60);
      }
    }

    &__box {
      &.first {
        @include respond(tab-port) {
          min-height: 18.6rem;
          margin-bottom: 9rem;
        }

        & .section__col2 {
          @include respond(tab-port) {
            margin-top: 6.7rem;
            display: flex;
            justify-content: flex-end;
            & .section__text {
              @include pcmin("margin-left", 120);
              max-width: 40rem;
            }
          }
        }
      }
      &.second {
        @include respond(tab-port) {
          margin-bottom: 6rem;
        }
        & .section__col1,
        & .section__col2,
        & .section__col3 {
          position: relative;
          & * {
            pointer-events: none;
          }
          & > a {
            pointer-events: auto;
            @include absolute(10);
            @include respond(hover) {
              &:hover {
                & ~ .about__image img {
                  transform: scale(1.05);
                }
                & ~ .section__link .arrow {
                  transform: translateX(1rem);
                }
                & ~ .section__link {
                  color: $color-primary;
                }
              }
            }
          }
          & .section__link {
            @include respond(sp) {
              @include max("margin-top", 10);
              @include max("margin-bottom", 40);
            }
            @include respond(tab-port) {
              margin-top: 2rem;
            }
          }
        }
        & .section__col2 {
          @include respond(sp) {
          }
          @include respond(tab-port) {
            margin-top: 9rem;
          }
        }
      }
    }
    &__link {
      @include respond(sp) {
        @include max('font-size', 14);
        letter-spacing: 0;
        line-height: 1.428;
      }
      @include respond(tab-port) {
        font-size: 1.8rem;
        letter-spacing: 0.07em;
        line-height: 1.3888;
        width: unset;
        & .arrow {
          align-self: center;
        }
      }
      // 丸矢印
      &.main {
        @include respond(sp) {
          @include max('font-size', 12);
          letter-spacing: 0;
          line-height: 1.416;
        }
        @include respond(tab-port) {
          margin-left: auto;
          margin-right: auto;
          font-size: 1.4rem;
          letter-spacing: 0;
          line-height: 1.428;
          width: max-content;
        }
      }
    }
  }

  // ABOUT
  & .about {
    &__image {
      position: relative;
      overflow: hidden;
      @include respond(sp) {
        // @include max("width", 260);
        // @include max("height", 200);
        & img {
          transition: 0.3s ease;
          @include max("width", 260);
          @include max("height", 200);
          @include max("border-radius", 10);
          object-fit: cover;
          display: block;
        }
      }
      @include respond(tab-port) {
        & img {
          transition: 0.3s ease;
          border-radius: 1rem;
          width: 100%;
          height: auto;
          object-fit: cover;
          display: block;
        }
      }
    }

    &__nums {
      position: absolute;
      z-index: 5;
      color: $color-menu-white;
      @include respond(sp) {
        @include max("font-size", 40);
        @include max("top", 10);
        @include max("left", 10);
        line-height: 1.75;
        letter-spacing: 0.04em;
      }
      @include respond(tab-port) {
        font-size: 4rem;

        top: 1rem;
        left: 2rem;
      }
    }
  }
}

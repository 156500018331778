@charset "UTF-8";
@mixin respond($breakpoint) {
  @if $breakpoint == big-phone {
    @media only screen and (min-width: 20.0625em) {
      @content;
    } //321px
  }
  @if $breakpoint == tab-port {
    @media only screen and (min-width: 768px) {
      @content;
    } //768px
  }
  @if $breakpoint == tab-land {
    @media only screen and (min-width: 64.0625em) {
      @content;
    } //1024px
  }
  @if $breakpoint == menu-break {
    @media only screen and (min-width: 83.125em) {
      @content;
    } //1330px
  }
  @if $breakpoint == pc {
    @media only screen and (min-width: 90.0625em) {
      @content;
    } //1440px
  }
  @if $breakpoint == big-pc {
    @media only screen and (min-width: 120.0625em) {
      @content;
    } //1920px
  }
  @if $breakpoint == hover {
    // @media (hover: hover) and (max-width: 48.0625em) {
    @media (hover: hover) {
      @content;
    } //hover
  }
  @if $breakpoint == sp {
    @media only screen and (max-width: 767px) {
      @content;
    } //767px
  }
  @if $breakpoint == min-tab-land {
    @media only screen and (max-width: 1024px) {
      @content;
    } //1024px
  }
  @if $breakpoint == min-menu-break {
    @media only screen and (max-width: 1329px) {
      @content;
    } //1329px
  }
}

// sp  vwから最小値をremで出力
// 使い方 プロパティとサイズを指定する
// @include max('font-size', 28);
@mixin max($property, $value) {
  #{$property}: max(#{sprem($value)}, #{vw($value)});
}
@mixin min($property, $value) {
  #{$property}: min(#{#{vw($value)}, sprem($value)});
}

// sp  marginやpadding
@mixin mp($property, $value1, $value2, $value3, $value4) {
  #{$property}: max(#{sprem($value1)}, #{vw($value1)})
    max(#{sprem($value2)}, #{vw($value2)})
    max(#{sprem($value3)}, #{vw($value3)})
    max(#{sprem($value4)}, #{vw($value4)});
}

// vwから最大値をremで出力
// 使い方 プロパティとサイズを指定する
// @include mv('font-size', 16);
@mixin mv($property, $value) {
  #{$property}: min(#{vwpc($value)}, #{rem($value)});
}

// margin, padding用
@mixin pcmp($property, $value1, $value2, $value3, $value4) {
  #{$property}: min(#{vwpc($value1)}, #{rem($value1)})
    min(#{vwpc($value2)}, #{rem($value2)})
    min(#{vwpc($value3)}, #{rem($value3)})
    min(#{vwpc($value4)}, #{rem($value4)});
}

// vwから最大値をremで出力
// 使い方 プロパティとサイズを指定する
// @include pcmin('font-size', 1.6);
// remが返されるので注意
@mixin pcmin($property, $value) {
  #{$property}: min(#{vwpc($value)}, #{rem($value)});
}

@mixin pcmax($property, $value) {
  #{$property}: max(#{rem($value)}, #{vwpc($value)});
}

// aタグの縦横100%
@mixin absolute($zIndex) {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: $zIndex;
  transition: 0.5s ease;
}

// header menu hover $elm (eg. p)
@mixin hoverAnim($elm) {
  @include respond(menu-break) {
    &.current {
      & #{$elm} {
        &::before {
          transform: translateX(-50%) scaleX(1);
          opacity: 1;
        }
      }
    }
    #{$elm} {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        right: 0;
        width: 6rem;
        height: 1px;
        background-color: $color-primary;
        transition: 0.3s ease;
        transform: translateX(-50%) scaleX(0);
        opacity: 0;
      }
    }
    @include respond(hover) {
      &:hover {
        & #{$elm} {
          &::before {
            transform: translateX(-50%) scaleX(1);
            opacity: 1;
          }
        }
      }
    }
  }
}

@mixin hover {
  transition: 0.3s ease;
  @include respond(hover) {
    &:hover {
      opacity: 0.7;
    }
  }
}

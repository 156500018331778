@charset "UTF-8";
.arrow {
  background: url("../images/common/arrow.svg") center / 100% no-repeat;
  @include max("width", 14);
  @include max("height", 14);
  flex-shrink: 0;
  display: inline-block;
  pointer-events: none;
  align-self: baseline;
  transition: 0.3s ease;
  @include respond(tab-port) {
    width: 14px;
    height: 14px;
  }
  &.rotate {
    transform: rotate(-45deg);
  }
  &.down {
    transform: rotate(90deg);
  }
  &.back {
    transform: rotate(180deg);
  }
  &.white {
    background: url("../images/common/arrow-white.svg") center / 100% no-repeat;
    background-size: auto;
  }
  &.black {
    background: url("../images/common/arrow-black.svg") center / 100% no-repeat;
  }
  &.primary {
    background: url("../images/common/arrow-primary.svg") center / 100%
      no-repeat;
    background-size: auto;
    align-self: center;
    @include respond(sp) {
      // @include max('width', 14);
      // @include max('height', 14);
    }
    @include respond(tab-port) {
      width: 15px;
      height: 15px;
    }
  }
  &.to-top {
    background: url("../images/common/arrow-black.svg") center / 100% no-repeat;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-90deg);
  }
  &.circle {
    position: relative;
    transform-origin: left;
    transition: 0.3s ease;
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border: 1px solid $color-red;
      border-radius: 50%;
      transition: 0.3s ease;
      @include respond(sp) {
        @include max("width", 50);
        @include max("height", 50);
      }
      @include respond(tab-port) {
        width: 8rem;
        height: 8rem;
      }
    }
    & ~ span {
      transition: 0.3s ease;
    }
  }
}

.sp-none {
  @include respond(sp) {
    display: none !important;
  }
  @include respond(tab-port) {
    display: block !important;
  }
}
.sp-none-ib {
  @include respond(sp) {
    display: none !important;
  }
  @include respond(tab-port) {
    display: inline-block !important;
  }
}
.sp-none-fl {
  @include respond(sp) {
    display: none !important;
  }
  @include respond(tab-port) {
    display: flex !important;
  }
}

.pc-none {
  @include respond(sp) {
    display: block !important;
  }
  @include respond(tab-port) {
    display: none !important;
  }
}

// footer用
.footer-sp-none {
  @include respond(min-menu-break) {
    display: none !important;
  }
  @include respond(menu-break) {
    display: block !important;
  }
}
.footer-pc-none {
  @include respond(min-menu-break) {
    display: block !important;
  }
  @include respond(menu-break) {
    display: none !important;
  }
}
.u-maxw-40 {
  @include respond(tab-port) {
    max-width: 40rem;
    display: flex;
    flex-direction: column;
    @include pcmin("margin-left", 120);
    margin-right: 0;
  }
}
.u-maxw-40-left {
  @include respond(tab-port) {
    max-width: 40rem;
    display: flex;
    flex-direction: column;
    @include pcmin("margin-right", 120);
    margin-left: 0;
  }
}

.u-text-center {
  text-align: center;
}

.u-sp-mt0 {
  @include respond(sp) {
    margin-top: 0 !important;
  }
}

.disabled {
  opacity: 0.5 !important;
  cursor: not-allowed !important;
  & * {
    pointer-events: none !important;
  }
}

.u-sp-hide {
  @include respond(sp) {
    display: none !important;
  }
}

.u-anchor {
  display: block;
  @include respond(sp) {
    padding-top: 6rem;
    margin-top: -6rem;
  }
  @include respond(tab-port) {
    // padding-top: 8rem;
    // margin-top: -8rem;
  }
}


@charset "UTF-8";
// section override
.section.footer {
  background-color: $color-menu-white;

  @include respond(sp) {
    @include max("padding-top", 30);
    @include max("padding-bottom", 60);
  }
  @include respond(tab-port) {
    padding-top: 9rem;
    padding-bottom: 8rem;
  }

  // section override
  & .section {

    &__box {
      @include respond(tab-port) {
        margin-bottom: 2rem;

      }
      & .section__col1{
        @include respond(tab-port) {
            margin-right: 2.3rem;
        }
      }
      & .section__col2 {
        @include respond(tab-port) {
        }
      }
    }
    &__link {
      &-half {
        @include respond(sp) {
          display: none;
        }
        @include respond(tab-port) {
        }
      }
    }
  }
}

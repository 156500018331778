@charset "UTF-8";
.footer__bottom {
  position: relative;
  background-color: $color-white;

  @include respond(min-menu-break) {
    font-size: 1.2rem;
    letter-spacing: 0;
    line-height: 1.416;
    margin: 0 auto;
    text-align: center;
    padding-top: 7rem;
    padding-bottom: 1rem;
  }
  @include respond(sp) {
    @include max("padding-top", 70);
    @include max("padding-bottom", 12);
    margin: 0 auto;
    text-align: center;

    @include max("font-size", 12);
    letter-spacing: 0;
    line-height: 1.416;
  }
  @include respond(menu-break) {
    font-size: 1.4rem;
    line-height: 1.428;
    letter-spacing: 0;
  }
  &-wrapper {
    @include respond(menu-break) {
      padding: 7rem 0 4.92rem 0;
      max-width: 128rem;
      margin: 0 auto;
    }
  }

  &-box {
    @include respond(menu-break) {
      display: flex;

      &.box2 {
        align-items: flex-end;
      }
    }
  }
  &-col {
    @include respond(menu-break) {
      width: 100%;
      &.first {
        min-width: 36rem;
        width: 36rem;
      }
    }
  }
  &-logo {
    position: relative;
    width: max-content;
    @include respond(min-menu-break) {
      margin-bottom: 2rem;
      margin-right: auto;
      margin-left: auto;
    }
    @include respond(sp) {
      @include max("margin-bottom", 20);
    }
    @include respond(menu-break) {
      margin-bottom: 1.63rem;
    }
    & a {
      @include absolute(10);
      @include respond(hover) {
        &:hover {
          & ~ img {
            opacity: 0.7;
          }
        }
      }
    }
    & img {
      display: block;
      object-fit: contain;
      @include respond(min-menu-break) {
        margin: 0 auto;
        width: 20rem;
        height: 2.5rem;
      }
      @include respond(sp) {
        margin: 0 auto;
        @include max("width", 200);
        @include max("height", 25);
      }
      @include respond(menu-break) {
        width: 23.8rem;
        height: 3.6rem;
      }
    }
  }
  &-info1 {
    @include respond(min-menu-break) {
      margin-bottom: 5px;
    }
    @include respond(sp) {
      @include max("margin-bottom", 5);
    }
    @include respond(menu-break) {
      margin-bottom: 1.5rem;
    }
  }
  &-info2 {
    @include respond(min-menu-break) {
      margin-bottom: 5px;
    }
    @include respond(sp) {
      @include max("margin-bottom", 5);
    }
    @include respond(menu-break) {
      margin-bottom: 1rem;
    }
  }

  &-map {
    position: relative;
    color: $color-primary;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    border-bottom: 1px solid $color-primary;
    margin: 0 auto;
    font-family: $font-noto;
    @include hover;

    & a {
      @include absolute(10);
    }
    @include respond(min-menu-break) {
      max-width: 10.3rem;
      margin-bottom: 3rem;
      padding-bottom: 5px;
      font-size: 1.2rem;
      letter-spacing: 0;
      line-height: 1.416;
    }
    @include respond(sp) {
      @include max("max-width", 103);
      @include max("margin-bottom", 30);
      @include max("padding-bottom", 5);
      @include max("font-size", 12);
      letter-spacing: 0;
      line-height: 1.416;
    }
    @include respond(menu-break) {
      max-width: 10.3rem;
      margin-left: 0;
      padding-bottom: 7px;
    }
  }
  &-instagram {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    font-family: $font-noto;

    & a {
      @include absolute(10);
    }
    & .instagram {
      position: relative;
      display: inline-block;
      background: url("../images/common/instagram.svg") center / 100% no-repeat;
      @include hover;
      @include respond(min-menu-break) {
        width: 81.37px;
        height: 18px;
      }
      @include respond(sp) {
        @include max("width", 81.37);
        @include max("height", 18);
      }
      @include respond(menu-break) {
        width: 81px;
        height: 18px;
        margin-top: 6.4rem;
      }
    }
    @include respond(min-menu-break) {
      max-width: 81.37px;
      margin-bottom: 3rem;
      font-size: 1.2rem;
      letter-spacing: 0;
      line-height: 1.5;
      @include hover;
    }
    @include respond(sp) {
      @include max("max-width", 81.37);
      @include max("margin-bottom", 30);
      @include max("font-size", 12);
      letter-spacing: 0;
      line-height: 1.5;
    }
  }
  &-privacy {
    @include respond(menu-break) {
      display: flex;
      justify-content: space-between;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        top: -2rem;
        left: 0;
        right: 0;
        height: 1px;
        background: $color-primary-light;
        width: 100%;
      }
    }

    & p {
      color: $color-primary-light;

      @include respond(min-menu-break) {
        letter-spacing: 0.04em;
        line-height: 1.08;
      }
      @include respond(sp) {
        letter-spacing: 0.04em;
        line-height: 1.08;
      }
      @include respond(menu-break) {
        justify-self: flex-end;
      }
    }
    &  a {
      @include respond(tab-port) {
        font-size: 1.2rem;
        letter-spacing: 0;
        line-height: 1.5;
      }
      @include hover;
      @include respond(menu-break) {
        color: $color-primary;
        &:first-child {
          margin-right: 4rem;
        }
      }
      & .arrow {
        margin-bottom: -0.3rem;
        margin-left: 4.2px;
      }
    }
  }
  &-to-top {
    & a {
      @include absolute(10);
    }
    position: absolute;
    transition: 0.3s ease;
    &::before {
      content: "";
      transition: 0.3s ease;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transform-origin: center;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: $color-secondary;
    }
    @include respond(min-menu-break) {
      left: 50%;
      transform: translateX(-50%);
      top: -3rem;
      width: 8rem;
      height: 8rem;
    }
    @include respond(sp) {
      left: 50%;
      transform: translateX(-50%);
      top: min(#{vw(-30)}, -3rem);
      @include max("width", 80);
      @include max("height", 80);
    }
    @include respond(menu-break) {
      width: 8rem;
      height: 8rem;
      top: 8rem;
      right: 8.3rem;
    }
    @include respond(hover) {
      &:hover {
        &::before {
          transform: scale(0.75);
        }
        & .arrow.to-top {
          transform: translate(-50%, calc(-50% - 1rem)) rotate(-90deg) scale(1);
        }
      }
    }
  }

  &-list {
    @include respond(menu-break) {
      display: flex;
      flex-wrap: wrap;
      gap: 4rem;
      width: 50rem;
      margin-top: 1rem;
    }
  }

  &-item {
    & {
      @include hoverAnim(p);
    }
    @include respond(menu-break) {
      position: relative;
      & a {
        @include absolute(10);
        display: inline-block;
      }
      & p {
        font-size: 1.8rem;
        line-height: 1.7777;
        letter-spacing: 0.07em;
      }
    }
    &.sm {
      @include respond(menu-break) {
        & p {
          font-size: 1.4rem;
          line-height: 1.428;
          letter-spacing: 0;
        }
      }
    }
  }
}

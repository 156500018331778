@charset "UTF-8";
.full-image {
  width: 100%;

  @include respond(sp) {
    background: url("../images/index/full-image@sp.jpg") no-repeat;
    background-size: cover;
    background-position: center;
    // background-attachment: fixed;
    @include max('height', 200);
    // height: 20.381rem;
  }
  @include respond(tab-port) {
    background: url("../images/index/full-image@2x.jpg") no-repeat;
    background-size: cover;
    background-position: center;
    // background-attachment: fixed;
    height: 30rem;
  }

  &-section {
    width: 100%;
    position: relative;
  }
}

@charset "UTF-8";
.header {
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 9600;
  background-color: $color-menu-white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: transform 0.6s $ease-out;
  transform: translateY(0);
  @include respond(sp) {
    @include max("padding-left", 30);
    height: $header-height;
    box-shadow: 0 #{max(#{sprem(3)}, #{vw(3)})} #{max(#{sprem(20)}, #{vw(20)})}
      rgba(0, 0, 0, 0.04);
  }
  @include respond(tab-port) {
    padding-left: 8rem;
    height: $header-height-pc;
    box-shadow: 0 3px 20px rgba(0, 0, 0, 0.04);
  }

  // openしたとき
  &.fixed {
    position: fixed;
    overflow-y: hidden;
    &.hide-top {
      animation: none;
    }
  }
  // scrollしたとき
  &.scroll-fixed {
    position: fixed;
    overflow-y: hidden;
    // animation: 0.5s ease-in-out forwards;
    &.fixed {
      animation: none;
    }
    // SPのときは常に表示させるのでtab-port
    @include respond(tab-port) {
      &.hide-top {
        // animation: 0.5s hideUp ease-in-out forwards;
        transform: translateY(-150%);

        &.fixed {
          animation: none;
        }
      }
    }

    &.show-down {
      transform: translateY(0);
      &.fixed {
        animation: none;
      }
    }
  }

  &__wrapper {
    width: 100%;
    // max-width: $container-width;
    // padding: 0 $padding;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: inherit;
  }
  &__logo {
    display: flex;
    align-items: center;
    position: relative;
    & a {
      @include absolute(10);
      @include respond(hover) {
        &:hover {
          & ~ img {
            opacity: 0.7;
          }
        }
      }
    }
    & img {
      display: flex;
      align-items: center;
      transition: 0.5s ease;
      @include respond(sp) {
        width: 18rem;
        height: 22.8px;
      }
      @include respond(tab-port) {
        width: 22rem;
        height: 27.92px;
      }
    }
  }

  // NAV
  &__nav {
    min-width: 101.5rem;
    display: none;

    @include respond(menu-break) {
      display: block;
    }
  }
  &__list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: inherit;
  }
  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: $header-height-pc;
    margin: 0 auto;
    font-size: 1.6rem;
    letter-spacing: -0.03em;
    line-height: 1.75;
    position: relative;
    & {
      @include hoverAnim(p);
    }
    &.news {
      margin-right: 6rem;
    }
    &.contact {
      width: 16rem;
      flex-direction: column;
      background-color: $color-secondary;
      margin-right: 0;
      margin-left: 0;
      font-size: 1.2rem;
      letter-spacing: 0;
      line-height: 1.6666;
      padding-top: 1rem;
      & p::before {
        display: none;
      }
    }
    &.shop {
      padding-top: 0.8rem;
      width: 16rem;
      flex-direction: column;
      background-color: $color-primary;
      color: #fff;
      margin: 0;
      font-size: 1.2rem;
      letter-spacing: 0;
      line-height: 1.6666;
      & p::before {
        display: none;
      }
      & .arrow {
        position: absolute;
        top: 5px;
        right: 5.2px;
        background-size: auto;
      }
    }
  }
  &__contact {
    background: url("../images/common/email.svg") center / 100% no-repeat;
    width: 30px;
    height: 26.45px;
    display: inline-block;
    pointer-events: none;
    margin-bottom: 10px;
  }
  &__shop {
    background: url("../images/common/shop.svg") center / 100% no-repeat;
    width: 30px;
    height: 32.31px;
    display: inline-block;
    pointer-events: none;
    margin-bottom: 4.7px;
  }

  &__link {
    @include absolute(10);
  }

  // menu open
  @include respond(menu-break) {
    &-menu {
      display: none;
    }
  }
  @include respond(min-menu-break) {
    &-menu {
      transition: 0.3s $ease-out;
      & * {
        transition: 0.8s $ease-out 0.2s;
        opacity: 0;
      }
      transform: translateY(-100%);
      display: block;
      background-color: $color-white;
      min-height: 100%;
      max-width: 100%;
      position: fixed;
      z-index: 9000;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: auto;
      outline: 0;
      &__list-wrapper {
        background-color: $color-menu-white;
        padding-top: $header-height-pc;
      }
      &__list {
        // max-width: 104rem;
        max-width: 32rem;
        margin: 0 auto;
        padding: 3rem 3rem 3.04rem;
        & .copy {
          font-size: 1.2rem;
          line-height: 1.08;
          letter-spacing: 0.04em;
          color: $color-primary-light;
        }
      }
      &__item {
        width: max-content;
        position: relative;
        margin-bottom: 2rem;

        & {
          @include hoverAnim(p);
        }
        @include respond(hover) {
          &:hover {
            & *,
            &.font-4,
            &.instagram {
              opacity: 0.7 !important;
            }
            &.contact {
              background-color: $color-hover-gray;
              & p,
              & .arrow {
                opacity: 1 !important;
              }
              & p::before {
                display: none;
              }
            }
            &.shop {
              background-color: $color-hover-primary;
              & p,
              & .arrow {
                opacity: 1 !important;
              }
              & p::before {
                display: none;
              }
            }
          }
        }
        & p {
          transition: 0.3s ease;
          &.font-1 {
            font-size: 1.6rem;
            line-height: 1.875;
            letter-spacing: 0;
          }
          &.font-2 {
            font-size: 1.3rem;
            line-height: 1.5384;
            letter-spacing: 0.05em;
          }
          &.font-3 {
            font-size: 1.2rem;
            line-height: 1.416;
            letter-spacing: 0;
          }
        }
        &.font-4 {
          & a {
            transition: 0.3s ease;
            color: $color-primary;
            font-size: 1.2rem;
            line-height: 1.5;
            letter-spacing: 0;
            display: flex;
            align-items: center;
            & .arrow {
              margin-left: 4.2px;
            }
          }
          &.first {
            margin-bottom: 1rem;
          }
        }

        &.contact {
          background-color: $color-white;
          border-radius: 1rem;
          margin-top: 3rem;
          margin-bottom: 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 1.4rem 1rem 1.4rem 2rem;
          min-width: 26rem;
          transition: 0.3s ease;
        }
        &.shop {
          background-color: $color-secondary;
          border-radius: 1rem;
          margin-top: 1.3rem;
          margin-bottom: 3rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 1.4rem 1rem 1.4rem 2rem;
          min-width: 26rem;
          transition: 0.3s ease;
        }
        &.instagram {
          width: 81px;
          height: 18px;
          display: inline-block;
          background: url("../images/common/instagram.svg") center / 100%
            no-repeat;
          margin-bottom: 3rem;
          transition: 0.3s ease;
        }
      }
      &__link {
        @include absolute(10);
      }

      &__list.list2 {
        padding: 3rem 3rem 3.02rem;
        background-color: $color-white;

        & h3 {
          font-size: 1.4rem;
          line-height: 1.428;
          letter-spacing: 0.05em;
          margin-bottom: 2rem;
        }
        & li {
          position: relative;
          width: max-content;
          &:not(:last-child) {
            margin-bottom: 1rem;
          }
          & a {
            @include absolute(10);
            @include respond(hover) {
              &:hover {
                & ~ p {
                  opacity: 0.7;
                }
              }
            }
          }
          & p {
            font-size: 1.4rem;
            line-height: 1.428;
            letter-spacing: 0;
            text-decoration: underline;
            display: flex;
            align-items: center;
            transition: 0.3s ease;
            & .arrow {
              margin-left: 4px;
            }
          }
        }
      }

      &.hide,
      &.show.hide {
        & * {
          opacity: 0;
        }
      }
      &.show {
        transform: none;
        & * {
          opacity: 1;
        }
      }
    }
    @include respond(sp) {
      &-menu {
        top: 0;
        &__list-wrapper {
          padding-top: $header-height;
        }
        &__list {
          max-width: 100%;
          height: max-content;
          @include mp("padding", 30, 30, 34, 30);
          & .copy {
            @include max("font-size", 12);
          }
        }
        &__item {
          @include max("margin-bottom", 20);
          & p {
            &.font-1 {
              @include max("font-size", 16);
            }
            &.font-2 {
              @include max("font-size", 13);
            }
            &.font-3 {
              @include max("font-size", 12);
            }
          }
          &.font-4 {
            & a {
              @include max("font-size", 12);
              & .arrow {
                @include max("margin-left", 4.2);
              }
            }
            &.first {
              @include max("margin-bottom", 10);
            }
          }

          &.contact {
            @include max("border-radius", 10);
            @include max("margin-top", 30);
            @include max("width", 260);
            @include mp("padding", 14, 10, 14, 20);
          }
          &.shop {
            @include max("border-radius", 10);
            @include max("margin-top", 13);
            @include max("width", 260);
            @include max("margin-bottom", 30);
            @include mp("padding", 14, 10, 14, 20);
          }
          &.instagram {
            @include max("width", 81);
            @include max("height", 18);
            @include max("margin-bottom", 30);
          }
        }
        &__link {
        }

        &__list.list2 {
          @include mp("padding", 30, 30, 32, 30);
          &:not(:last-child) {
            @include max("margin-bottom", 10);
          }
          & h3 {
            @include max("font-size", 14);
            @include max("margin-bottom", 20);
          }
          & li {
            position: relative;
            & a {
              @include absolute(10);
            }
            & p {
              @include max("font-size", 14);
              & .arrow {
                @include max("margin-left", 4);
              }
            }
          }
        }
      }
    }
  }
}

@charset "UTF-8";
.top {
  width: 100%;

  &__wrapper {
    position: relative;
    overflow: hidden;
    width: inherit;
    height: inherit;
    @include respond(sp) {
      height: 100vh; /* Fallback */
      height: calc(var(--vh, 1vh) * 100);
    }
    @include respond(tab-port) {
      padding-top: $header-height-pc;
      height: calc(100vh - #{$top-news-height});
      height: calc((var(--vh, 1vh) * 100) - #{$top-news-height});
    }
  }

  &-swiper-outer,
  &-swiper,
  &__image {
    width: 100%;
    height: 100%;
  }
  &-swiper-outer {
    z-index: 100;
    overflow: hidden;
  }

  // swiper
  &__image {
    overflow: hidden;
    pointer-events: none;
    & img {
      display: block;
      width: 100%;
      height: 100%;
      // height: 100vh;
      object-fit: cover;
    }
  }

  &__texts {
    position: absolute;
    z-index: 200;
    @include respond(sp) {
      @include max("bottom", 30);
      @include max("left", 30);
    }
    @include respond(tab-port) {
      bottom: 4rem;
      left: 8rem;
    }

    & h1 {
      @include max("font-size", 18);
      @include max("margin-bottom", 20);
      line-height: 1.777;
      letter-spacing: 0.07em;
      @include respond(tab-port) {
        line-height: 1.714;
        letter-spacing: 0.05em;
        font-size: 2.8rem;
        margin-bottom: 4rem;
      }
    }
    & a {
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      @include max("border-radius", 10);
      @include max("max-width", 200);
      @include mp("padding", 14, 72, 14, 20);
      @include max("font-size", 12);
      letter-spacing: 0;
      line-height: 1.416;
      white-space: nowrap;
      position: relative;
      transition: 0.5s ease;
      @include respond(tab-port) {
        border-radius: 1rem;
        padding: 2rem 5.4rem 2rem 3rem;
        max-width: 21rem;
        font-size: 1.4rem;
        line-height: 1.428;
      }
      & .arrow {
        position: absolute;
        @include max("top", 5);
        @include max("right", 5.2);

        @include respond(tab-port) {
          top: 0.5rem;
          right: 0.52rem;
        }
      }
      @include respond(hover) {
        &:hover {
          background-color: $color-white;
        }
      }
    }
  }
  &__scroll {
    writing-mode: vertical-rl;
    position: absolute;
    z-index: 50;
    @include max("font-size", 14);
    line-height: 1.214;
    letter-spacing: 0.04em;
    bottom: 0;
    @include max("padding-bottom", 37);
    @include max("right", 30);
    @include max("padding-right", 6);
    border-right: 1px solid $color-black;
    @include respond(tab-port) {
      display: none;
    }
  }

  // top-news
  &__news {
    @include respond(tab-port) {
      height: $top-news-height;
      width: inherit;
      background-color: $color-white;
      &-wrapper {
        display: flex;
        // max-width: $container-width;
        padding: 0 $padding;
        margin: 0 auto;
        width: 100%;
        justify-content: space-between;
      }

      &-swiper-outer {
        padding-right: 9rem;
        overflow: hidden;
      }
      &-link {
        // flex-basis: 13.89%;
        width: 12rem;
        display: flex;
        align-items: center;
        white-space: nowrap;
        @include hover;
        & .arrow {
          display: inline-block;
          margin-left: 1.5rem;
          margin-top: auto;
          margin-bottom: auto;
        }
      }

      &-swiper-outer,
      &-swiper,
      &-swiper-wrapper {
        height: $top-news-height;
      }
    }
    &__list {
      @include respond(sp) {
      }
    }
    &__item {
      position: relative;
      display: grid;
      grid-template-columns: repeat(2, max-content) 1fr;
      height: 100%;
      align-items: center;
      justify-content: center;
      @include hover;
      & a {
        @include absolute(10);
      }
    }

    &__date {
      font-size: 1.4rem;
      line-height: 1.428;
      letter-spacing: 0;
      font-family: $font-noto;
      font-weight: 300;
      margin-right: 1.5rem;
      white-space: nowrap;
      min-width: 6.7rem;
    }

    &__cat,
    &__text {
      font-family: $font-noto;

      // width: 360px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 1.4rem;
      letter-spacing: 0;
      line-height: 1.428;
    }
    &__text {
      @include respond(tab-port) {
        max-width: 88.3rem;
        width: 100%;
      }
    }
    &__cat {
      position: relative;
      color: $color-primary;
      // margin-right: 4rem;
      // min-width: 6.7rem;
      max-width: 9.5rem;

      // width: 6.5em;
      min-width: 6.7rem;
      margin-right: 4rem;

      &::before {
        content: "#";
        color: inherit;
        padding-right: 0.2em;
      }
    }
  }
}

// pagination
.top-pagination.swiper-pagination-bullets {
  @include respond(sp) {
    display: none;
  }
  @include respond(tab-port) {
    position: absolute;
    right: 8rem;
    bottom: 4rem;
    left: auto;
    z-index: 2;
    width: max-content;

    // height: 30px;
    // line-height: 30px;
    text-align: center;
    font-size: 1.4rem;
    border-radius: 50%;

    & button {
      // color: #37373c;
    }
    & button,
    & svg {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      height: 100%;
      width: 100%;
      // fill: $color-primary;
    }

    & .swiper-pagination-bullet.custom-dot {
      width: 1rem;
      height: 1rem;
      position: relative;
      // activeの背景色
      background: $color-primary;
      transition: all 0.5s;
      opacity: 1;
      margin-left: 0;
      &:not(:last-child) {
        margin-right: 1rem;
      }
      &:last-child {
        margin-right: 0;
      }
      & circle {
        fill: $color-menu-white;
        stroke: $color-menu-white;
        stroke-width: 4;
        stroke-dasharray: 85;
        // stroke-dashoffset: 85;
        transform-origin: 50%;
        transform: rotate(-90deg);
        transition: fill 0.5s, opacity 1.5s;

        // グレー枠線
        &.circle-one {
          stroke: rgba(50, 50, 50, 0.2);
          transition: opacity 1.5s;
          opacity: 0;
        }
      }
    }
    & .swiper-pagination-bullet-active.custom-dot {
      & circle {
        // stroke-dashoffset: 0;
        // 背景色を出現させる
        fill: transparent;
        // これを移動させて線を描く
        stroke-dashoffset: 85;

        &.circle-one {
          transition: opacity 1.5s;
          opacity: 1;
        }
        // 枠線色追加アニメーション
        &.circle-two {
          // animation: drawCircle 8.2s linear;
          animation: drawCircle 8.2s linear forwards;
        }
      }
    }
  }
}

@charset "UTF-8";
.section.product {
  background-color: $color-menu-white;
  padding-left: 0;
  padding-right: 0;
  position: relative;

  @include respond(sp) {
    @include max("padding-top", 108);
    @include max("padding-bottom", 50);
  }
  @include respond(tab-port) {
    padding-top: 6rem;
    padding-bottom: 8.8rem;
  }

  // waveの場所設定
  & .wave-wrapper {
    @include respond(sp) {
    }
    @include respond(tab-port) {
      top: 9.5rem;
    }
  }

  // section override
  & .section {
    &__wrapper {
      @include respond(sp) {
        @include max("padding-left", 30);
        @include max("padding-right", 30);
      }
      @include respond(tab-port) {
        padding-left: 8rem;
        padding-right: 8rem;
        max-width: 120rem;
      }
    }
    &__title {
      @include respond(tab-port) {
        align-self: flex-end;
      }
    }
    &__image {
      @include respond(sp) {
        @include max("margin-top", 20);
        @include max("margin-bottom", 20);
      }
    }
    &__text {
      &.lg {
        @include respond(sp) {
          @include max("margin-bottom", 34);
          @include max("padding-bottom", 7);
          @include max("padding-top", 7);
        }
        @include respond(tab-port) {
          margin-bottom: 0;
          width: 105%;
          padding: 16px 0;
        }
      }
    }
    &__en {
      @include respond(sp) {
        @include max("margin-bottom", 15);
      }
      @include respond(tab-port) {
        margin-bottom: 2rem;
      }
    }

    &__box {
      @include respond(sp) {
      }
      @include respond(tab-port) {
      }

      &.first {
        @include respond(tab-port) {
          margin-bottom: 6rem;
        }

        & .section__col1 {
          @include respond(sp) {
          }
          @include respond(tab-port) {
            display: flex;
          }
        }
        & .section__col2 {
          @include respond(sp) {
          }
          @include respond(tab-port) {
          }
        }
      }
      &.second {
        & .section__col1 {
          @include respond(sp) {
          }
          @include respond(tab-port) {
          }
        }
        & .section__col2 {
          @include respond(sp) {
          }
          @include respond(tab-port) {
            height: 53.4rem;
            position: relative;
            display: flex;
            align-items: flex-end;
            justify-content: center;
          }
        }
      }
    }
    &__col1 {
      @include respond(sp) {
      }
      @include respond(tab-port) {
      }
    }
    &__col2 {
      @include respond(sp) {
      }
      @include respond(tab-port) {
      }
    }
    &__link {
      @include respond(tab-port) {
        pointer-events: none;

        grid-column: 1 / span 1;
        grid-row: 3 / span 1;
      }
      @include respond(hover) {
        &:hover {
          .arrow {
            transform: translateX(1rem);
          }
        }
      }
    }
  }

  // PRODUCT
  & .product {
    &__circles {
      @include respond(tab-port) {
        margin-top: 9.4rem;
        margin-left: 7rem;
      }
    }
    &__circle {
      @include respond(sp) {
        display: none;
      }
      @include respond(tab-port) {
        background-color: $color-white;
        border-radius: 50%;
        display: inline-block;
        position: absolute;
        z-index: 5;
        &.circle1 {
          width: 5rem;
          height: 5rem;
          top: 0;
          left: 15.5rem;
        }
        &.circle2 {
          width: 10rem;
          height: 10rem;
          top: 3.7rem;
          left: 20.3rem;
        }
      }
    }
    &__content {
      @include respond(sp) {
        background-color: $color-white;
        @include max("border-radius", 10);
        @include mp("padding", 30, 25, 30, 25);
        position: relative;
      }
      @include respond(tab-port) {
        position: relative;
        z-index: 10;
        width: 100%;
        max-width: 46rem;
        height: 44rem;
        display: grid;
        grid-template-columns: repeat(2, min-content);
        grid-template-rows: repeat(3, min-content);
        align-content: center;
        justify-content: center;
      }
      & picture,
      & img {
        pointer-events: none;
        @include respond(sp) {
          display: none;
        }
        @include respond(tab-port) {
          grid-column: 2 / span 1;
          grid-row: 1 / span 1;
        }
      }
      & h3 {
        pointer-events: none;
        line-height: 1.7777;
        letter-spacing: 0.07em;
        @include respond(sp) {
          @include max("font-size", 18);
          @include max("margin-bottom", 5);
        }
        @include respond(tab-port) {
          width: max-content;
          font-size: 1.8rem;
          margin-bottom: 1rem;
        }
      }
      & .product__grid-title,
      & p {
        pointer-events: none;
      }
    }
    &__bg {
      transition: 0.3s ease;
      @include respond(sp) {
        position: absolute;
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
        background-color: $color-white;
        @include max("width", 20);
        @include max("height", 20);
        top: min(#{vw(-10)}, #{sprem(-10)});
      }
      @include respond(tab-port) {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        @include pcmin("width", 460);
        @include pcmin("height", 440);
        background-color: $color-white;
        z-index: -1;
        border-radius: 50%;
        min-width: 362.5px;
        min-height: 346.8px;
        @include respond(hover) {
          &:hover {
            transform: translate(-50%, -50%) scale(1.05);
            & ~ .section__link .arrow {
              transform: translateX(1rem);
            }
          }
        }
      }
      & a {
        border-radius: 50%;
        @include absolute(10);
      }
    }
    &__grid-title {
      @include respond(sp) {
        display: contents;
      }
      @include respond(tab-port) {
        grid-column: 1 / span 1;
        grid-row: 1 / span 1;
        align-self: flex-end;
      }
    }
    &__text {
      @include respond(sp) {
        @include max("margin-bottom", 20);
        @include max("font-size", 14);
        line-height: 1.7857;
        letter-spacing: 0;
        text-align: justify;
      }

      @include respond(tab-port) {
        padding: 3px 0;
        margin-bottom: 2.7rem;
        font-size: 1.4rem;
        line-height: 1.714;
        letter-spacing: 0.05em;
        grid-column: 1 / span 2;
        grid-row: 2 / span 1;
      }
    }
    &__link {
      @include respond(sp) {
      }
      @include respond(tab-port) {
      }
    }
  }
}

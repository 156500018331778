@charset "UTF-8";
.footer__links {
  @include respond(min-menu-break) {
    display: none;
  }
  @include respond(menu-break) {
    width: 100%;
    background-color: $color-primary-light;

    &-wrapper {
      max-width: 132rem;
      margin: 0 auto;
      & ul {
        display: flex;
        align-items: center;
      }
      & li {
        position: relative;
        height: 100%;
        padding: 2rem;
        @include hover;
        &.no-underline {
          &:hover {
            opacity: 1;
          }
        }
        & a {
          display: inline-block;
          @include absolute(10);
          font-size: 1.4rem;
          line-height: 1.428;
          letter-spacing: 0;
        }
        & p {
          color: $color-menu-white;
          font-size: 1.4rem;
          line-height: 1.428;
          letter-spacing: 0;
          display: flex;
          align-items: center;
          text-decoration: underline;
          &.no-underline {
            text-decoration: none;
            margin-right: 2rem;
          }
          & .arrow {
            margin-left: 4px;
            background-size: auto;
          }
        }
      }
    }
  }
}

@charset "UTF-8";
// section override
.section.news {
  background-color: $color-white;

  @include respond(sp) {
    @include max("padding-top", 40);
    @include max("padding-bottom", 34);
    @include max("margin-bottom", 6);
  }
  @include respond(tab-port) {
    padding-top: 9rem;
    padding-bottom: 6rem;
  }

  // section override
  & .section {
    &__title {
      @include respond(sp) {
        @include max("margin-bottom", 40);
        @include max("font-size", 18);
        line-height: 1.7777;
        letter-spacing: 0.07em;
      }
      @include respond(tab-port) {
        margin-bottom: 3rem;
      }
    }
    &__box {
      @include respond(tab-port) {
        display: grid;
        @include mv('column-gap', 127);
      }
      & .section__col1{
        @include respond(tab-port) {
          grid-column: 1 / span 1;
          grid-row: 1 / span 1;
        }
      }
      & .section__col2 {
        @include respond(tab-port) {
          grid-column: 2 / span 1;
          grid-row: 1 / span 2;
        }
      }
    }
    &__link {
      &.main {
        @include respond(tab-port) {
          grid-column: 1 / span 1;
        }
      }
    }
  }
}

// NEWS
.news {
  &__wrapper {
    @include respond(tab-port) {
      max-width: 104rem;
      margin: 0 auto;
    }
  }

  &__list {
    @include respond(sp) {
      @include max("margin-bottom", 20);
    }
    @include respond(tab-port) {
      display: grid;
      align-content: space-between;
      height: 100%;
      max-height: 21rem;
    }
  }
  &__item {
    position: relative;
    @include hover;
    cursor: pointer;
    & a {
      @include absolute(10);
    }
    @include respond(sp) {
      position: relative;
      @include max("padding-top", 20);
      @include max("padding-bottom", 20);
      display: grid;
      grid-template-columns: min-content 1fr;
      @include max("column-gap", 10);
      &:first-child::after,
      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 1px;
        width: 100%;
        background-color: $color-secondary;
      }
      &:first-child::after {
        bottom: auto;
        top: 0;
      }
    }
    @include respond(tab-port) {
      display: grid;
      grid-template-columns: repeat(2, max-content) 1fr;
      height: 100%;
      align-content: space-between;
      justify-content: center;
    }
  }

  &__date {
    font-family: $font-noto;
    font-weight: 300;
    white-space: nowrap;
    @include respond(sp) {
      @include max("font-size", 12);
      letter-spacing: 0;
      line-height: 1.416;
      grid-column: 1 / span 1;
    }
    @include respond(tab-port) {
      font-size: 1.4rem;
      line-height: 1.428;
      letter-spacing: 0;
      margin-right: 2rem;
      min-width: 6.7rem;
    }
  }

  &__cat,
  &__text {
    font-family: $font-noto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @include respond(tab-port) {
      font-size: 1.4rem;
      line-height: 1.428;
      letter-spacing: 0;
    }
  }
  &__text {
    width: 100%;
    @include respond(sp) {
      @include max("font-size", 14);
      letter-spacing: 0;
      line-height: 1.428;
      @include max("padding-top", 3);
      @include max("padding-bottom", 3);
      grid-column: 1 / span 2;
      grid-row: 2 / span 1;
    }
    @include respond(tab-port) {
      max-width: 52.6rem;
      justify-self: flex-end;
    }
  }
  &__cat {
    position: relative;
    color: $color-primary;

    @include respond(sp) {
      @include max("font-size", 12);
      letter-spacing: 0;
      line-height: 1.416;
      grid-column: 2 / span 1;
    }
    @include respond(tab-port) {
      width: calc(5em + 4rem);
      margin-right: 2rem;
    }

    &::before {
      content: "#";
      color: inherit;
      padding-right: 0.2em;
    }
  }
}

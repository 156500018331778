@charset "UTF-8";
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul, li {
  list-style: none;
}

blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

button, input, select, textarea {
  border: 0;
}

button {
  outline: none;
  background-color: transparent;
}

a,
a:hover,
a:active,
a:focus {
  text-decoration: none;
  outline: none;
}

/* vw for sp
============================================================= */
/* vw to rem for small-phone
============================================================= */
/* vw for pc
============================================================= */
/* vw to rem for big-pc
============================================================= */
/* ROUND
============================================================= */
.appear.up .item {
  transform: translateY(15px);
}

.appear.down .item {
  transform: translateY(-150%);
}

.appear.left .item {
  transform: translateX(150%);
}

.appear.right .item {
  transform: translateX(-150%);
}

.appear.zoom-out .item {
  transform: scale(1.1);
}

.appear .item {
  transition: opacity 1.2s cubic-bezier(0.17, 0.935, 0.305, 1), transform 1.5s cubic-bezier(0.17, 0.935, 0.305, 1);
  opacity: 0;
}
.appear.inview .item {
  opacity: 1;
  transform: none;
}
.appear.inview .item:nth-child(1) {
  transition-delay: 0.1s;
}
.appear.inview .item:nth-child(2) {
  transition-delay: 0.2s;
}
.appear.inview .item:nth-child(3) {
  transition-delay: 0.3s;
}
.appear.inview .item:nth-child(4) {
  transition-delay: 0.4s;
}
.appear.inview .item:nth-child(5) {
  transition-delay: 0.5s;
}
.appear.inview .item:nth-child(6) {
  transition-delay: 0.6s;
}
.appear.inview .item:nth-child(7) {
  transition-delay: 0.7s;
}
.appear.inview .item:nth-child(8) {
  transition-delay: 0.8s;
}
.appear.inview .item:nth-child(9) {
  transition-delay: 0.9s;
}
.appear.inview .item:nth-child(10) {
  transition-delay: 1s;
}
.appear .anim .s-item {
  transition: opacity 1.2s cubic-bezier(0.17, 0.935, 0.305, 1), transform 1.5s cubic-bezier(0.17, 0.935, 0.305, 1);
  opacity: 0;
}
.appear .anim .s-item.up {
  transform: translateY(15px);
}
.appear .anim .s-item.center-up {
  transform: translate(-50%, 15px);
}
.appear .anim .s-item.zoom-out {
  transform: scale(1.1);
}
.appear .anim .s-item.center-zoom-out {
  transform: translateX(-50%) scale(1.1);
}
.appear .anim .s-item.d-block {
  display: block;
}
.appear .anim .s-item.d-grid {
  display: grid;
}
.appear .anim .s-item.d-flex {
  display: flex;
}
.appear .anim .s-item.opacity {
  opacity: 0;
}
.appear.inview .anim .s-item {
  opacity: 1;
  transform: none;
}
.appear.inview .anim .s-item.center-up, .appear.inview .anim .s-item.center-zoom-out {
  opacity: 1;
  transform: translateX(-50%);
}

.appear-this-item.up {
  transform: translateY(15px);
}

.appear-this-item.down {
  transform: translateY(-150%);
}

.appear-this-item.left {
  transform: translateX(150%);
}

.appear-this-item.right {
  transform: translateX(-150%);
}

.appear-this-item.zoom-out {
  transform: scale(1.1);
}

.appear-this-item {
  transition: opacity 1.2s cubic-bezier(0.17, 0.935, 0.305, 1), transform 1.5s cubic-bezier(0.17, 0.935, 0.305, 1);
  opacity: 0;
}
.appear-this-item.duration2 {
  transition-duration: 2s;
}
.appear-this-item.duration4 {
  transition-duration: 4s;
}
.appear-this-item.inview {
  opacity: 1 !important;
  transform: none;
}

.delay1 {
  transition-delay: 0.1s !important;
}

.delay2 {
  transition-delay: 0.2s !important;
}

.delay3 {
  transition-delay: 0.3s !important;
}

.delay4 {
  transition-delay: 0.4s !important;
}

.delay5 {
  transition-delay: 0.5s !important;
}

.delay6 {
  transition-delay: 0.6s !important;
}

.delay7 {
  transition-delay: 0.7s !important;
}

.delay8 {
  transition-delay: 0.8s !important;
}

.delay9 {
  transition-delay: 0.9s !important;
}

.delay10 {
  transition-delay: 1s !important;
}

.delay11 {
  transition-delay: 1.1s !important;
}

.delay12 {
  transition-delay: 1.2s !important;
}

.delay13 {
  transition-delay: 1.3s !important;
}

.delay14 {
  transition-delay: 1.4s !important;
}

.delay15 {
  transition-delay: 1.5s !important;
}

.delay16 {
  transition-delay: 1.6s !important;
}

.delay17 {
  transition-delay: 1.7s !important;
}

.delay18 {
  transition-delay: 1.8s !important;
}

.delay19 {
  transition-delay: 1.9s !important;
}

.delay20 {
  transition-delay: 2s !important;
}

.appear.serials .item {
  transition: opacity 1.2s cubic-bezier(0.17, 0.935, 0.305, 1), transform 1.5s cubic-bezier(0.17, 0.935, 0.305, 1);
  opacity: 0;
}
.appear.serials.inview .item {
  opacity: 1;
  transform: none;
}
.appear.serials.inview .item:nth-child(1) {
  transition-delay: 0.1s;
}
.appear.serials.inview .item:nth-child(2) {
  transition-delay: 0.2s;
}
.appear.serials.inview .item:nth-child(3) {
  transition-delay: 0.3s;
}
.appear.serials.inview .item:nth-child(4) {
  transition-delay: 0.4s;
}
.appear.serials.inview .item:nth-child(5) {
  transition-delay: 0.5s;
}
.appear.serials.inview .item:nth-child(6) {
  transition-delay: 0.6s;
}
.appear.serials.inview .item:nth-child(7) {
  transition-delay: 0.7s;
}
.appear.serials.inview .item:nth-child(8) {
  transition-delay: 0.8s;
}
.appear.serials.inview .item:nth-child(9) {
  transition-delay: 0.9s;
}
.appear.serials.inview .item:nth-child(10) {
  transition-delay: 1s;
}
.appear.serials.inview .item:nth-child(11) {
  transition-delay: 1.1s;
}
.appear.serials.inview .item:nth-child(12) {
  transition-delay: 1.2s;
}
.appear.serials.inview .item:nth-child(13) {
  transition-delay: 1.3s;
}
.appear.serials.inview .item:nth-child(14) {
  transition-delay: 1.4s;
}
.appear.serials.inview .item:nth-child(15) {
  transition-delay: 1.5s;
}
.appear.serials.inview .item:nth-child(16) {
  transition-delay: 1.6s;
}
.appear.serials.inview .item:nth-child(17) {
  transition-delay: 1.7s;
}
.appear.serials.inview .item:nth-child(18) {
  transition-delay: 1.8s;
}
.appear.serials.inview .item:nth-child(19) {
  transition-delay: 1.9s;
}
.appear.serials.inview .item:nth-child(20) {
  transition-delay: 2s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1 !important;
    visibility: visible;
  }
  100% {
    opacity: 0 !important;
    visibility: hidden;
  }
}
@keyframes showDown {
  0% {
    transform: translateY(-150%);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes hideUp {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-150%);
  }
}
@keyframes name-up {
  0% {
    transform: translateY(150%);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes drawCircle {
  0% {
    stroke-dashoffset: 85;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
* {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
  scroll-padding-top: 82px;
  animation: 0.8s fadeIn forwards;
}
@media only screen and (max-width: 767px) {
  html {
    scroll-padding-top: 6rem;
  }
}
@media only screen and (min-width: 768px) {
  html {
    scroll-padding-top: 8rem;
  }
}

body {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Bellefair", "Noto Sans JP", sans-serif;
  font-weight: 400;
  font-size: 1.5rem;
  width: 100%;
  color: #2b3433;
  line-height: 1.6666;
  letter-spacing: 0.02em;
}
body.fixed {
  width: 100%;
  height: 100%;
  position: fixed;
}
body.overflow-hidden {
  overflow: hidden !important;
}

.wrapper,
body {
  position: relative;
}

.wrapper {
  overflow: hidden;
}

a {
  text-decoration: none;
  color: #2b3433;
  transition: 0.5s ease;
}

/* autoprefixer: ignore next */
_::-webkit-full-page-media,
_:future,
:root html {
  min-height: -webkit-fill-available;
}

_::-webkit-full-page-media,
_:future,
:root body {
  min-height: -webkit-fill-available;
  min-height: 100vh;
}

.arrow {
  background: url("../images/common/arrow.svg") center/100% no-repeat;
  width: max(1.4rem, 4.38vw);
  height: max(1.4rem, 4.38vw);
  flex-shrink: 0;
  display: inline-block;
  pointer-events: none;
  align-self: baseline;
  transition: 0.3s ease;
}
@media only screen and (min-width: 768px) {
  .arrow {
    width: 14px;
    height: 14px;
  }
}
.arrow.rotate {
  transform: rotate(-45deg);
}
.arrow.down {
  transform: rotate(90deg);
}
.arrow.back {
  transform: rotate(180deg);
}
.arrow.white {
  background: url("../images/common/arrow-white.svg") center/100% no-repeat;
  background-size: auto;
}
.arrow.black {
  background: url("../images/common/arrow-black.svg") center/100% no-repeat;
}
.arrow.primary {
  background: url("../images/common/arrow-primary.svg") center/100% no-repeat;
  background-size: auto;
  align-self: center;
}
@media only screen and (min-width: 768px) {
  .arrow.primary {
    width: 15px;
    height: 15px;
  }
}
.arrow.to-top {
  background: url("../images/common/arrow-black.svg") center/100% no-repeat;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-90deg);
}
.arrow.circle {
  position: relative;
  transform-origin: left;
  transition: 0.3s ease;
}
.arrow.circle::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #ea8472;
  border-radius: 50%;
  transition: 0.3s ease;
}
@media only screen and (max-width: 767px) {
  .arrow.circle::before {
    width: max(5rem, 15.63vw);
    height: max(5rem, 15.63vw);
  }
}
@media only screen and (min-width: 768px) {
  .arrow.circle::before {
    width: 8rem;
    height: 8rem;
  }
}
.arrow.circle ~ span {
  transition: 0.3s ease;
}

@media only screen and (max-width: 767px) {
  .sp-none {
    display: none !important;
  }
}
@media only screen and (min-width: 768px) {
  .sp-none {
    display: block !important;
  }
}

@media only screen and (max-width: 767px) {
  .sp-none-ib {
    display: none !important;
  }
}
@media only screen and (min-width: 768px) {
  .sp-none-ib {
    display: inline-block !important;
  }
}

@media only screen and (max-width: 767px) {
  .sp-none-fl {
    display: none !important;
  }
}
@media only screen and (min-width: 768px) {
  .sp-none-fl {
    display: flex !important;
  }
}

@media only screen and (max-width: 767px) {
  .pc-none {
    display: block !important;
  }
}
@media only screen and (min-width: 768px) {
  .pc-none {
    display: none !important;
  }
}

@media only screen and (max-width: 1329px) {
  .footer-sp-none {
    display: none !important;
  }
}
@media only screen and (min-width: 83.125em) {
  .footer-sp-none {
    display: block !important;
  }
}

@media only screen and (max-width: 1329px) {
  .footer-pc-none {
    display: block !important;
  }
}
@media only screen and (min-width: 83.125em) {
  .footer-pc-none {
    display: none !important;
  }
}

@media only screen and (min-width: 768px) {
  .u-maxw-40 {
    max-width: 40rem;
    display: flex;
    flex-direction: column;
    margin-left: min(8.33vw, 12rem);
    margin-right: 0;
  }
}

@media only screen and (min-width: 768px) {
  .u-maxw-40-left {
    max-width: 40rem;
    display: flex;
    flex-direction: column;
    margin-right: min(8.33vw, 12rem);
    margin-left: 0;
  }
}

.u-text-center {
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .u-sp-mt0 {
    margin-top: 0 !important;
  }
}

.disabled {
  opacity: 0.5 !important;
  cursor: not-allowed !important;
}
.disabled * {
  pointer-events: none !important;
}

@media only screen and (max-width: 767px) {
  .u-sp-hide {
    display: none !important;
  }
}

.u-anchor {
  display: block;
}
@media only screen and (max-width: 767px) {
  .u-anchor {
    padding-top: 6rem;
    margin-top: -6rem;
  }
}
.section.footer {
  background-color: #fdffff;
}
@media only screen and (max-width: 767px) {
  .section.footer {
    padding-top: max(3rem, 9.38vw);
    padding-bottom: max(6rem, 18.75vw);
  }
}
@media only screen and (min-width: 768px) {
  .section.footer {
    padding-top: 9rem;
    padding-bottom: 8rem;
  }
}
@media only screen and (min-width: 768px) {
  .section.footer .section__box {
    margin-bottom: 2rem;
  }
}
@media only screen and (min-width: 768px) {
  .section.footer .section__box .section__col1 {
    margin-right: 2.3rem;
  }
}
@media only screen and (max-width: 767px) {
  .section.footer .section__link-half {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .footer__middle {
    display: none;
  }
}
@media only screen and (min-width: 768px) {
  .footer__middle {
    width: 100%;
    height: 33rem;
    display: flex;
  }
  .footer__middle-col {
    position: relative;
    width: 100%;
    padding: 5rem 0 5rem 8rem;
  }
}
@media only screen and (min-width: 768px) and (hover: hover) {
  .footer__middle-col:hover .footer__middle-image img {
    transform: scale(1.05);
  }
  .footer__middle-col:hover .section__link.main .arrow {
    transform: translateX(1rem);
  }
  .footer__middle-col:hover .section__link.main .circle::before {
    transform: translate(calc(-50% - 1rem), -50%) scale(0.75);
  }
  .footer__middle-col:hover .section__link.main .arrow.white {
    transform: translate(1rem, -1rem);
  }
  .footer__middle-col:hover .section__link.main .white.circle::before {
    transform: translate(calc(-50% - 1rem), calc(-50% + 1rem)) scale(0.75);
  }
  .footer__middle-col:hover .section__link.main .circle ~ span {
    transform: translateX(0.5rem);
  }
}
@media only screen and (min-width: 768px) {
  .footer__middle-col.left * {
    color: #fdffff;
  }
  .footer__middle-col.left .section__en.title::before {
    background-color: #fdffff;
  }
  .footer__middle-col.left .arrow::before {
    border: 1px solid #fdffff;
  }
}
@media only screen and (min-width: 768px) {
  .footer__middle-col.right * {
    color: #2b3433;
  }
  .footer__middle-col.right .section__en.title::before {
    background-color: #2b3433;
  }
}
@media only screen and (min-width: 768px) {
  .footer__middle-image {
    overflow: hidden;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
  .footer__middle-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    transition: 0.3s ease;
  }
}
.footer__middle-link {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  transition: 0.5s ease;
}
.footer__middle .section__title {
  padding-bottom: 0;
  margin-bottom: 2rem;
}
.footer__middle .section__link.main {
  margin-left: 0.3rem;
}

.footer__bottom {
  position: relative;
  background-color: #f4f7f8;
}
@media only screen and (max-width: 1329px) {
  .footer__bottom {
    font-size: 1.2rem;
    letter-spacing: 0;
    line-height: 1.416;
    margin: 0 auto;
    text-align: center;
    padding-top: 7rem;
    padding-bottom: 1rem;
  }
}
@media only screen and (max-width: 767px) {
  .footer__bottom {
    padding-top: max(7rem, 21.88vw);
    padding-bottom: max(1.2rem, 3.75vw);
    margin: 0 auto;
    text-align: center;
    font-size: max(1.2rem, 3.75vw);
    letter-spacing: 0;
    line-height: 1.416;
  }
}
@media only screen and (min-width: 83.125em) {
  .footer__bottom {
    font-size: 1.4rem;
    line-height: 1.428;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 83.125em) {
  .footer__bottom-wrapper {
    padding: 7rem 0 4.92rem 0;
    max-width: 128rem;
    margin: 0 auto;
  }
}
@media only screen and (min-width: 83.125em) {
  .footer__bottom-box {
    display: flex;
  }
  .footer__bottom-box.box2 {
    align-items: flex-end;
  }
}
@media only screen and (min-width: 83.125em) {
  .footer__bottom-col {
    width: 100%;
  }
  .footer__bottom-col.first {
    min-width: 36rem;
    width: 36rem;
  }
}
.footer__bottom-logo {
  position: relative;
  width: max-content;
}
@media only screen and (max-width: 1329px) {
  .footer__bottom-logo {
    margin-bottom: 2rem;
    margin-right: auto;
    margin-left: auto;
  }
}
@media only screen and (max-width: 767px) {
  .footer__bottom-logo {
    margin-bottom: max(2rem, 6.25vw);
  }
}
@media only screen and (min-width: 83.125em) {
  .footer__bottom-logo {
    margin-bottom: 1.63rem;
  }
}
.footer__bottom-logo a {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  transition: 0.5s ease;
}
@media (hover: hover) {
  .footer__bottom-logo a:hover ~ img {
    opacity: 0.7;
  }
}
.footer__bottom-logo img {
  display: block;
  object-fit: contain;
}
@media only screen and (max-width: 1329px) {
  .footer__bottom-logo img {
    margin: 0 auto;
    width: 20rem;
    height: 2.5rem;
  }
}
@media only screen and (max-width: 767px) {
  .footer__bottom-logo img {
    margin: 0 auto;
    width: max(20rem, 62.5vw);
    height: max(2.5rem, 7.81vw);
  }
}
@media only screen and (min-width: 83.125em) {
  .footer__bottom-logo img {
    width: 23.8rem;
    height: 3.6rem;
  }
}
@media only screen and (max-width: 1329px) {
  .footer__bottom-info1 {
    margin-bottom: 5px;
  }
}
@media only screen and (max-width: 767px) {
  .footer__bottom-info1 {
    margin-bottom: max(0.5rem, 1.56vw);
  }
}
@media only screen and (min-width: 83.125em) {
  .footer__bottom-info1 {
    margin-bottom: 1.5rem;
  }
}
@media only screen and (max-width: 1329px) {
  .footer__bottom-info2 {
    margin-bottom: 5px;
  }
}
@media only screen and (max-width: 767px) {
  .footer__bottom-info2 {
    margin-bottom: max(0.5rem, 1.56vw);
  }
}
@media only screen and (min-width: 83.125em) {
  .footer__bottom-info2 {
    margin-bottom: 1rem;
  }
}
.footer__bottom-map {
  position: relative;
  color: #38a095;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  border-bottom: 1px solid #38a095;
  margin: 0 auto;
  font-family: "Noto Sans JP", sans-serif;
  transition: 0.3s ease;
}
@media (hover: hover) {
  .footer__bottom-map:hover {
    opacity: 0.7;
  }
}
.footer__bottom-map a {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  transition: 0.5s ease;
}
@media only screen and (max-width: 1329px) {
  .footer__bottom-map {
    max-width: 10.3rem;
    margin-bottom: 3rem;
    padding-bottom: 5px;
    font-size: 1.2rem;
    letter-spacing: 0;
    line-height: 1.416;
  }
}
@media only screen and (max-width: 767px) {
  .footer__bottom-map {
    max-width: max(10.3rem, 32.19vw);
    margin-bottom: max(3rem, 9.38vw);
    padding-bottom: max(0.5rem, 1.56vw);
    font-size: max(1.2rem, 3.75vw);
    letter-spacing: 0;
    line-height: 1.416;
  }
}
@media only screen and (min-width: 83.125em) {
  .footer__bottom-map {
    max-width: 10.3rem;
    margin-left: 0;
    padding-bottom: 7px;
  }
}
.footer__bottom-instagram {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  font-family: "Noto Sans JP", sans-serif;
}
.footer__bottom-instagram a {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  transition: 0.5s ease;
}
.footer__bottom-instagram .instagram {
  position: relative;
  display: inline-block;
  background: url("../images/common/instagram.svg") center/100% no-repeat;
  transition: 0.3s ease;
}
@media (hover: hover) {
  .footer__bottom-instagram .instagram:hover {
    opacity: 0.7;
  }
}
@media only screen and (max-width: 1329px) {
  .footer__bottom-instagram .instagram {
    width: 81.37px;
    height: 18px;
  }
}
@media only screen and (max-width: 767px) {
  .footer__bottom-instagram .instagram {
    width: max(8.137rem, 25.43vw);
    height: max(1.8rem, 5.63vw);
  }
}
@media only screen and (min-width: 83.125em) {
  .footer__bottom-instagram .instagram {
    width: 81px;
    height: 18px;
    margin-top: 6.4rem;
  }
}
@media only screen and (max-width: 1329px) {
  .footer__bottom-instagram {
    max-width: 81.37px;
    margin-bottom: 3rem;
    font-size: 1.2rem;
    letter-spacing: 0;
    line-height: 1.5;
    transition: 0.3s ease;
  }
}
@media only screen and (max-width: 1329px) and (hover: hover) {
  .footer__bottom-instagram:hover {
    opacity: 0.7;
  }
}
@media only screen and (max-width: 767px) {
  .footer__bottom-instagram {
    max-width: max(8.137rem, 25.43vw);
    margin-bottom: max(3rem, 9.38vw);
    font-size: max(1.2rem, 3.75vw);
    letter-spacing: 0;
    line-height: 1.5;
  }
}
@media only screen and (min-width: 83.125em) {
  .footer__bottom-privacy {
    display: flex;
    justify-content: space-between;
    position: relative;
  }
  .footer__bottom-privacy::before {
    content: "";
    position: absolute;
    top: -2rem;
    left: 0;
    right: 0;
    height: 1px;
    background: #8fbbc1;
    width: 100%;
  }
}
.footer__bottom-privacy p {
  color: #8fbbc1;
}
@media only screen and (max-width: 1329px) {
  .footer__bottom-privacy p {
    letter-spacing: 0.04em;
    line-height: 1.08;
  }
}
@media only screen and (max-width: 767px) {
  .footer__bottom-privacy p {
    letter-spacing: 0.04em;
    line-height: 1.08;
  }
}
@media only screen and (min-width: 83.125em) {
  .footer__bottom-privacy p {
    justify-self: flex-end;
  }
}
.footer__bottom-privacy a {
  transition: 0.3s ease;
}
@media only screen and (min-width: 768px) {
  .footer__bottom-privacy a {
    font-size: 1.2rem;
    letter-spacing: 0;
    line-height: 1.5;
  }
}
@media (hover: hover) {
  .footer__bottom-privacy a:hover {
    opacity: 0.7;
  }
}
@media only screen and (min-width: 83.125em) {
  .footer__bottom-privacy a {
    color: #38a095;
  }
  .footer__bottom-privacy a:first-child {
    margin-right: 4rem;
  }
}
.footer__bottom-privacy a .arrow {
  margin-bottom: -0.3rem;
  margin-left: 4.2px;
}
.footer__bottom-to-top {
  position: absolute;
  transition: 0.3s ease;
}
.footer__bottom-to-top a {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  transition: 0.5s ease;
}
.footer__bottom-to-top::before {
  content: "";
  transition: 0.3s ease;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform-origin: center;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #daeaef;
}
@media only screen and (max-width: 1329px) {
  .footer__bottom-to-top {
    left: 50%;
    transform: translateX(-50%);
    top: -3rem;
    width: 8rem;
    height: 8rem;
  }
}
@media only screen and (max-width: 767px) {
  .footer__bottom-to-top {
    left: 50%;
    transform: translateX(-50%);
    top: min(-9.38vw, -3rem);
    width: max(8rem, 25vw);
    height: max(8rem, 25vw);
  }
}
@media only screen and (min-width: 83.125em) {
  .footer__bottom-to-top {
    width: 8rem;
    height: 8rem;
    top: 8rem;
    right: 8.3rem;
  }
}
@media (hover: hover) {
  .footer__bottom-to-top:hover::before {
    transform: scale(0.75);
  }
  .footer__bottom-to-top:hover .arrow.to-top {
    transform: translate(-50%, calc(-50% - 1rem)) rotate(-90deg) scale(1);
  }
}
@media only screen and (min-width: 83.125em) {
  .footer__bottom-list {
    display: flex;
    flex-wrap: wrap;
    gap: 4rem;
    width: 50rem;
    margin-top: 1rem;
  }
}
@media only screen and (min-width: 83.125em) {
  .footer__bottom-item.current p::before {
    transform: translateX(-50%) scaleX(1);
    opacity: 1;
  }
  .footer__bottom-item p {
    position: relative;
  }
  .footer__bottom-item p::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    right: 0;
    width: 6rem;
    height: 1px;
    background-color: #38a095;
    transition: 0.3s ease;
    transform: translateX(-50%) scaleX(0);
    opacity: 0;
  }
}
@media only screen and (min-width: 83.125em) and (hover: hover) {
  .footer__bottom-item:hover p::before {
    transform: translateX(-50%) scaleX(1);
    opacity: 1;
  }
}
@media only screen and (min-width: 83.125em) {
  .footer__bottom-item {
    position: relative;
  }
  .footer__bottom-item a {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    z-index: 10;
    transition: 0.5s ease;
    display: inline-block;
  }
  .footer__bottom-item p {
    font-size: 1.8rem;
    line-height: 1.7777;
    letter-spacing: 0.07em;
  }
}
@media only screen and (min-width: 83.125em) {
  .footer__bottom-item.sm p {
    font-size: 1.4rem;
    line-height: 1.428;
    letter-spacing: 0;
  }
}

@media only screen and (max-width: 1329px) {
  .footer__links {
    display: none;
  }
}
@media only screen and (min-width: 83.125em) {
  .footer__links {
    width: 100%;
    background-color: #8fbbc1;
  }
  .footer__links-wrapper {
    max-width: 132rem;
    margin: 0 auto;
  }
  .footer__links-wrapper ul {
    display: flex;
    align-items: center;
  }
  .footer__links-wrapper li {
    position: relative;
    height: 100%;
    padding: 2rem;
    transition: 0.3s ease;
  }
}
@media only screen and (min-width: 83.125em) and (hover: hover) {
  .footer__links-wrapper li:hover {
    opacity: 0.7;
  }
}
@media only screen and (min-width: 83.125em) {
  .footer__links-wrapper li.no-underline:hover {
    opacity: 1;
  }
}
@media only screen and (min-width: 83.125em) {
  .footer__links-wrapper li a {
    display: inline-block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    z-index: 10;
    transition: 0.5s ease;
    font-size: 1.4rem;
    line-height: 1.428;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 83.125em) {
  .footer__links-wrapper li p {
    color: #fdffff;
    font-size: 1.4rem;
    line-height: 1.428;
    letter-spacing: 0;
    display: flex;
    align-items: center;
    text-decoration: underline;
  }
  .footer__links-wrapper li p.no-underline {
    text-decoration: none;
    margin-right: 2rem;
  }
  .footer__links-wrapper li p .arrow {
    margin-left: 4px;
    background-size: auto;
  }
}

.header {
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 9600;
  background-color: #fdffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: transform 0.6s cubic-bezier(0.17, 0.935, 0.305, 1);
  transform: translateY(0);
}
@media only screen and (max-width: 767px) {
  .header {
    padding-left: max(3rem, 9.38vw);
    height: 6rem;
    box-shadow: 0 max(0.3rem, 0.94vw) max(2rem, 6.25vw) rgba(0, 0, 0, 0.04);
  }
}
@media only screen and (min-width: 768px) {
  .header {
    padding-left: 8rem;
    height: 8rem;
    box-shadow: 0 3px 20px rgba(0, 0, 0, 0.04);
  }
}
.header.fixed {
  position: fixed;
  overflow-y: hidden;
}
.header.fixed.hide-top {
  animation: none;
}
.header.scroll-fixed {
  position: fixed;
  overflow-y: hidden;
}
.header.scroll-fixed.fixed {
  animation: none;
}
@media only screen and (min-width: 768px) {
  .header.scroll-fixed.hide-top {
    transform: translateY(-150%);
  }
  .header.scroll-fixed.hide-top.fixed {
    animation: none;
  }
}
.header.scroll-fixed.show-down {
  transform: translateY(0);
}
.header.scroll-fixed.show-down.fixed {
  animation: none;
}
.header__wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: inherit;
}
.header__logo {
  display: flex;
  align-items: center;
  position: relative;
}
.header__logo a {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  transition: 0.5s ease;
}
@media (hover: hover) {
  .header__logo a:hover ~ img {
    opacity: 0.7;
  }
}
.header__logo img {
  display: flex;
  align-items: center;
  transition: 0.5s ease;
}
@media only screen and (max-width: 767px) {
  .header__logo img {
    width: 18rem;
    height: 22.8px;
  }
}
@media only screen and (min-width: 768px) {
  .header__logo img {
    width: 22rem;
    height: 27.92px;
  }
}
.header__nav {
  min-width: 101.5rem;
  display: none;
}
@media only screen and (min-width: 83.125em) {
  .header__nav {
    display: block;
  }
}
.header__list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: inherit;
}
.header__item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 8rem;
  margin: 0 auto;
  font-size: 1.6rem;
  letter-spacing: -0.03em;
  line-height: 1.75;
  position: relative;
}
@media only screen and (min-width: 83.125em) {
  .header__item.current p::before {
    transform: translateX(-50%) scaleX(1);
    opacity: 1;
  }
  .header__item p {
    position: relative;
  }
  .header__item p::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    right: 0;
    width: 6rem;
    height: 1px;
    background-color: #38a095;
    transition: 0.3s ease;
    transform: translateX(-50%) scaleX(0);
    opacity: 0;
  }
}
@media only screen and (min-width: 83.125em) and (hover: hover) {
  .header__item:hover p::before {
    transform: translateX(-50%) scaleX(1);
    opacity: 1;
  }
}
.header__item.news {
  margin-right: 6rem;
}
.header__item.contact {
  width: 16rem;
  flex-direction: column;
  background-color: #daeaef;
  margin-right: 0;
  margin-left: 0;
  font-size: 1.2rem;
  letter-spacing: 0;
  line-height: 1.6666;
  padding-top: 1rem;
}
.header__item.contact p::before {
  display: none;
}
.header__item.shop {
  padding-top: 0.8rem;
  width: 16rem;
  flex-direction: column;
  background-color: #38a095;
  color: #fff;
  margin: 0;
  font-size: 1.2rem;
  letter-spacing: 0;
  line-height: 1.6666;
}
.header__item.shop p::before {
  display: none;
}
.header__item.shop .arrow {
  position: absolute;
  top: 5px;
  right: 5.2px;
  background-size: auto;
}
.header__contact {
  background: url("../images/common/email.svg") center/100% no-repeat;
  width: 30px;
  height: 26.45px;
  display: inline-block;
  pointer-events: none;
  margin-bottom: 10px;
}
.header__shop {
  background: url("../images/common/shop.svg") center/100% no-repeat;
  width: 30px;
  height: 32.31px;
  display: inline-block;
  pointer-events: none;
  margin-bottom: 4.7px;
}
.header__link {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  transition: 0.5s ease;
}
@media only screen and (min-width: 83.125em) {
  .header-menu {
    display: none;
  }
}
@media only screen and (max-width: 1329px) {
  .header-menu {
    transition: 0.3s cubic-bezier(0.17, 0.935, 0.305, 1);
    transform: translateY(-100%);
    display: block;
    background-color: #f4f7f8;
    min-height: 100%;
    max-width: 100%;
    position: fixed;
    z-index: 9000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    outline: 0;
  }
  .header-menu * {
    transition: 0.8s cubic-bezier(0.17, 0.935, 0.305, 1) 0.2s;
    opacity: 0;
  }
  .header-menu__list-wrapper {
    background-color: #fdffff;
    padding-top: 8rem;
  }
  .header-menu__list {
    max-width: 32rem;
    margin: 0 auto;
    padding: 3rem 3rem 3.04rem;
  }
  .header-menu__list .copy {
    font-size: 1.2rem;
    line-height: 1.08;
    letter-spacing: 0.04em;
    color: #8fbbc1;
  }
  .header-menu__item {
    width: max-content;
    position: relative;
    margin-bottom: 2rem;
  }
}
@media only screen and (max-width: 1329px) and (min-width: 83.125em) {
  .header-menu__item.current p::before {
    transform: translateX(-50%) scaleX(1);
    opacity: 1;
  }
  .header-menu__item p {
    position: relative;
  }
  .header-menu__item p::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    right: 0;
    width: 6rem;
    height: 1px;
    background-color: #38a095;
    transition: 0.3s ease;
    transform: translateX(-50%) scaleX(0);
    opacity: 0;
  }
}
@media only screen and (max-width: 1329px) and (min-width: 83.125em) and (hover: hover) {
  .header-menu__item:hover p::before {
    transform: translateX(-50%) scaleX(1);
    opacity: 1;
  }
}
@media only screen and (max-width: 1329px) and (hover: hover) {
  .header-menu__item:hover *, .header-menu__item:hover.font-4, .header-menu__item:hover.instagram {
    opacity: 0.7 !important;
  }
  .header-menu__item:hover.contact {
    background-color: #EAF2F5;
  }
  .header-menu__item:hover.contact p, .header-menu__item:hover.contact .arrow {
    opacity: 1 !important;
  }
  .header-menu__item:hover.contact p::before {
    display: none;
  }
  .header-menu__item:hover.shop {
    background-color: #CDE2E8;
  }
  .header-menu__item:hover.shop p, .header-menu__item:hover.shop .arrow {
    opacity: 1 !important;
  }
  .header-menu__item:hover.shop p::before {
    display: none;
  }
}
@media only screen and (max-width: 1329px) {
  .header-menu__item p {
    transition: 0.3s ease;
  }
  .header-menu__item p.font-1 {
    font-size: 1.6rem;
    line-height: 1.875;
    letter-spacing: 0;
  }
  .header-menu__item p.font-2 {
    font-size: 1.3rem;
    line-height: 1.5384;
    letter-spacing: 0.05em;
  }
  .header-menu__item p.font-3 {
    font-size: 1.2rem;
    line-height: 1.416;
    letter-spacing: 0;
  }
}
@media only screen and (max-width: 1329px) {
  .header-menu__item.font-4 a {
    transition: 0.3s ease;
    color: #38a095;
    font-size: 1.2rem;
    line-height: 1.5;
    letter-spacing: 0;
    display: flex;
    align-items: center;
  }
  .header-menu__item.font-4 a .arrow {
    margin-left: 4.2px;
  }
  .header-menu__item.font-4.first {
    margin-bottom: 1rem;
  }
}
@media only screen and (max-width: 1329px) {
  .header-menu__item.contact {
    background-color: #f4f7f8;
    border-radius: 1rem;
    margin-top: 3rem;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.4rem 1rem 1.4rem 2rem;
    min-width: 26rem;
    transition: 0.3s ease;
  }
}
@media only screen and (max-width: 1329px) {
  .header-menu__item.shop {
    background-color: #daeaef;
    border-radius: 1rem;
    margin-top: 1.3rem;
    margin-bottom: 3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.4rem 1rem 1.4rem 2rem;
    min-width: 26rem;
    transition: 0.3s ease;
  }
}
@media only screen and (max-width: 1329px) {
  .header-menu__item.instagram {
    width: 81px;
    height: 18px;
    display: inline-block;
    background: url("../images/common/instagram.svg") center/100% no-repeat;
    margin-bottom: 3rem;
    transition: 0.3s ease;
  }
}
@media only screen and (max-width: 1329px) {
  .header-menu__link {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    z-index: 10;
    transition: 0.5s ease;
  }
}
@media only screen and (max-width: 1329px) {
  .header-menu__list.list2 {
    padding: 3rem 3rem 3.02rem;
    background-color: #f4f7f8;
  }
  .header-menu__list.list2 h3 {
    font-size: 1.4rem;
    line-height: 1.428;
    letter-spacing: 0.05em;
    margin-bottom: 2rem;
  }
  .header-menu__list.list2 li {
    position: relative;
    width: max-content;
  }
  .header-menu__list.list2 li:not(:last-child) {
    margin-bottom: 1rem;
  }
  .header-menu__list.list2 li a {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    z-index: 10;
    transition: 0.5s ease;
  }
}
@media only screen and (max-width: 1329px) and (hover: hover) {
  .header-menu__list.list2 li a:hover ~ p {
    opacity: 0.7;
  }
}
@media only screen and (max-width: 1329px) {
  .header-menu__list.list2 li p {
    font-size: 1.4rem;
    line-height: 1.428;
    letter-spacing: 0;
    text-decoration: underline;
    display: flex;
    align-items: center;
    transition: 0.3s ease;
  }
  .header-menu__list.list2 li p .arrow {
    margin-left: 4px;
  }
}
@media only screen and (max-width: 1329px) {
  .header-menu.hide *, .header-menu.show.hide * {
    opacity: 0;
  }
}
@media only screen and (max-width: 1329px) {
  .header-menu.show {
    transform: none;
  }
  .header-menu.show * {
    opacity: 1;
  }
}
@media only screen and (max-width: 1329px) and (max-width: 767px) {
  .header-menu {
    top: 0;
  }
  .header-menu__list-wrapper {
    padding-top: 6rem;
  }
  .header-menu__list {
    max-width: 100%;
    height: max-content;
    padding: max(3rem, 9.38vw) max(3rem, 9.38vw) max(3.4rem, 10.63vw) max(3rem, 9.38vw);
  }
  .header-menu__list .copy {
    font-size: max(1.2rem, 3.75vw);
  }
  .header-menu__item {
    margin-bottom: max(2rem, 6.25vw);
  }
  .header-menu__item p.font-1 {
    font-size: max(1.6rem, 5vw);
  }
  .header-menu__item p.font-2 {
    font-size: max(1.3rem, 4.06vw);
  }
  .header-menu__item p.font-3 {
    font-size: max(1.2rem, 3.75vw);
  }
  .header-menu__item.font-4 a {
    font-size: max(1.2rem, 3.75vw);
  }
  .header-menu__item.font-4 a .arrow {
    margin-left: max(0.42rem, 1.31vw);
  }
  .header-menu__item.font-4.first {
    margin-bottom: max(1rem, 3.13vw);
  }
  .header-menu__item.contact {
    border-radius: max(1rem, 3.13vw);
    margin-top: max(3rem, 9.38vw);
    width: max(26rem, 81.25vw);
    padding: max(1.4rem, 4.38vw) max(1rem, 3.13vw) max(1.4rem, 4.38vw) max(2rem, 6.25vw);
  }
  .header-menu__item.shop {
    border-radius: max(1rem, 3.13vw);
    margin-top: max(1.3rem, 4.06vw);
    width: max(26rem, 81.25vw);
    margin-bottom: max(3rem, 9.38vw);
    padding: max(1.4rem, 4.38vw) max(1rem, 3.13vw) max(1.4rem, 4.38vw) max(2rem, 6.25vw);
  }
  .header-menu__item.instagram {
    width: max(8.1rem, 25.31vw);
    height: max(1.8rem, 5.63vw);
    margin-bottom: max(3rem, 9.38vw);
  }
  .header-menu__list.list2 {
    padding: max(3rem, 9.38vw) max(3rem, 9.38vw) max(3.2rem, 10vw) max(3rem, 9.38vw);
  }
  .header-menu__list.list2:not(:last-child) {
    margin-bottom: max(1rem, 3.13vw);
  }
  .header-menu__list.list2 h3 {
    font-size: max(1.4rem, 4.38vw);
    margin-bottom: max(2rem, 6.25vw);
  }
  .header-menu__list.list2 li {
    position: relative;
  }
  .header-menu__list.list2 li a {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    z-index: 10;
    transition: 0.5s ease;
  }
  .header-menu__list.list2 li p {
    font-size: max(1.4rem, 4.38vw);
  }
  .header-menu__list.list2 li p .arrow {
    margin-left: max(0.4rem, 1.25vw);
  }
}

.section {
  width: 100%;
}
@media only screen and (max-width: 767px) {
  .section {
    padding-left: max(3rem, 9.38vw);
    padding-right: max(3rem, 9.38vw);
  }
}
@media only screen and (min-width: 768px) {
  .section {
    padding-left: 8rem;
    padding-right: 8rem;
  }
}
@media only screen and (min-width: 768px) {
  .section__wrapper {
    max-width: 104rem;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 767px) {
  .section__breadcrumb {
    margin-top: max(1rem, 3.13vw);
    margin-bottom: max(3rem, 9.38vw);
  }
}
@media only screen and (min-width: 768px) {
  .section__breadcrumb {
    margin-top: 2rem;
    margin-bottom: 3.2rem;
  }
}
.section__breadcrumb span, .section__breadcrumb a {
  display: inline-block;
}
@media only screen and (max-width: 767px) {
  .section__breadcrumb span, .section__breadcrumb a {
    font-size: max(1rem, 3.13vw);
    letter-spacing: 0;
    line-height: 1.5;
    font-family: "Noto Sans JP", sans-serif;
  }
}
@media only screen and (min-width: 768px) {
  .section__breadcrumb span, .section__breadcrumb a {
    font-size: 1.3rem;
    letter-spacing: -0.02em;
    line-height: 1.5384;
  }
}
.section__breadcrumb a {
  color: #38a095;
  transition: 0.3s ease;
}
@media (hover: hover) {
  .section__breadcrumb a:hover {
    opacity: 0.7;
  }
}
@media only screen and (max-width: 767px) {
  .section__breadcrumb a {
    margin-right: max(0.6rem, 1.88vw);
    display: none;
  }
  .section__breadcrumb a:first-child {
    display: inline-block;
  }
}
@media only screen and (min-width: 768px) {
  .section__breadcrumb a {
    margin-right: 2rem;
  }
}
.section__breadcrumb .b-arrow {
  color: #2b3433;
  display: inline-block;
}
@media only screen and (max-width: 767px) {
  .section__breadcrumb .b-arrow {
    margin-right: max(1rem, 3.13vw);
  }
}
@media only screen and (min-width: 768px) {
  .section__breadcrumb .b-arrow {
    margin-right: 2rem;
  }
}
@media only screen and (min-width: 768px) {
  .section__box {
    display: flex;
  }
}
.section__box.col3 {
  justify-content: space-between;
  gap: min(3.82vw, 5.5rem);
}
.section__box.col3 .section__col1, .section__box.col3 .section__col2, .section__box.col3 .section__col3 {
  flex-basis: 33%;
}
.section__col1 {
  position: relative;
}
@media only screen and (min-width: 768px) {
  .section__col1 {
    flex-basis: 50%;
  }
}
.section__col2 {
  position: relative;
}
@media only screen and (min-width: 768px) {
  .section__col2 {
    flex-basis: 50%;
  }
}
@media only screen and (max-width: 767px) {
  .section__title {
    font-size: max(2.8rem, 8.75vw);
    line-height: 1.5;
    letter-spacing: 0.05em;
    padding-top: max(0.7rem, 2.19vw);
    padding-bottom: max(0.7rem, 2.19vw);
  }
}
@media only screen and (min-width: 768px) {
  .section__title {
    font-size: 4.2rem;
    line-height: 1.6666;
    letter-spacing: 0.05em;
    padding: 14px 0;
  }
  .section__title.secondary {
    font-size: 2.8rem;
    line-height: 1.5;
    padding: 7px 0;
  }
}
.section__title.underline {
  position: relative;
}
@media only screen and (max-width: 767px) {
  .section__title.underline {
    padding-bottom: max(1.7rem, 5.31vw);
    margin-bottom: max(2rem, 6.25vw);
  }
}
@media only screen and (min-width: 768px) {
  .section__title.underline {
    padding: 15px 0;
    margin-bottom: 5rem;
    width: max-content;
    font-size: 5rem;
    line-height: 1.6;
  }
}
.section__title.underline::before {
  content: "";
  position: absolute;
  left: 0;
  background-color: #8fbbc1;
  height: 1px;
}
@media only screen and (max-width: 767px) {
  .section__title.underline::before {
    bottom: 0;
    width: max(5rem, 15.63vw);
  }
}
@media only screen and (min-width: 768px) {
  .section__title.underline::before {
    bottom: -2rem;
    width: 5rem;
  }
}
@media only screen and (max-width: 767px) {
  .section__h3 {
    font-size: max(1.8rem, 5.63vw);
    line-height: 1.7777;
    letter-spacing: 0.07em;
    padding-top: max(0.5rem, 1.56vw);
    padding-bottom: max(0.5rem, 1.56vw);
  }
}
@media only screen and (min-width: 768px) {
  .section__h3 {
    margin-top: 2rem;
    font-size: 2.8rem;
    line-height: 1.5;
    letter-spacing: 0.05em;
    padding: 7px 0;
  }
}
@media only screen and (max-width: 767px) {
  .section__h4 {
    font-size: max(1.6rem, 5vw);
    line-height: 2;
    letter-spacing: 0.07em;
  }
}
@media only screen and (min-width: 768px) {
  .section__h4 {
    font-size: 1.8rem;
    line-height: 1.777;
    letter-spacing: 0.07em;
  }
}
@media only screen and (max-width: 767px) {
  .section__image {
    width: 100%;
    height: auto;
  }
}
@media only screen and (min-width: 768px) {
  .section__image {
    width: 100%;
  }
}
.section__image img {
  object-fit: cover;
  display: block;
}
@media only screen and (max-width: 767px) {
  .section__image img {
    width: 100%;
    height: max(17.5rem, 54.69vw);
  }
}
@media only screen and (min-width: 768px) {
  .section__image img {
    width: 100%;
    height: auto;
  }
}
.section__en {
  line-height: 1.1428;
  letter-spacing: 0.04em;
  color: #38a095;
}
@media only screen and (max-width: 767px) {
  .section__en {
    font-size: max(1.4rem, 4.38vw);
    line-height: 1.1428;
    letter-spacing: 0.04em;
  }
}
@media only screen and (min-width: 768px) {
  .section__en {
    font-size: 1.4rem;
  }
}
.section__en.title {
  position: relative;
}
@media only screen and (max-width: 767px) {
  .section__en.title {
    padding-bottom: max(2rem, 6.25vw);
  }
}
@media only screen and (min-width: 768px) {
  .section__en.title {
    padding-bottom: 3rem;
  }
}
.section__en.title::before {
  content: "";
  position: absolute;
  left: 0;
  background-color: #38a095;
  height: 1px;
}
@media only screen and (max-width: 767px) {
  .section__en.title::before {
    bottom: 0;
    width: max(5rem, 15.63vw);
  }
}
@media only screen and (min-width: 768px) {
  .section__en.title::before {
    bottom: 0;
    width: 5rem;
  }
}
.section__en.title.u-text-center::before {
  left: 50%;
  transform: translateX(-50%);
}
.section__text {
  font-family: "Noto Sans JP", sans-serif;
}
@media only screen and (max-width: 767px) {
  .section__text {
    font-size: max(1.4rem, 4.38vw);
    padding-top: max(0.5rem, 1.56vw);
    padding-bottom: max(0.5rem, 1.56vw);
    line-height: 1.7857;
    letter-spacing: 0;
    text-align: justify;
  }
}
@media only screen and (min-width: 768px) {
  .section__text {
    font-size: 1.6rem;
    line-height: 1.875;
    letter-spacing: 0.03em;
    padding: 7px 0;
  }
}
@media only screen and (max-width: 767px) {
  .section__text.lg {
    font-size: max(1.6rem, 5vw);
    line-height: 1.857;
    letter-spacing: 0.03em;
  }
}
@media only screen and (min-width: 768px) {
  .section__text.lg {
    font-size: 1.8rem;
    line-height: 2.7777;
    letter-spacing: 0.07em;
  }
}
.section__link {
  width: max-content;
  display: flex;
  align-items: center;
  transition: 0.3s ease;
}
@media only screen and (max-width: 767px) {
  .section__link {
    font-size: max(1.2rem, 3.75vw);
    line-height: 1.416;
    letter-spacing: 0;
  }
  .section__link .arrow {
    margin-right: max(1rem, 3.13vw);
  }
  .section__link .arrow.circle {
    margin-left: max(1.8rem, 5.63vw);
    margin-right: max(2.8rem, 8.75vw);
    height: max(5rem, 15.63vw);
  }
}
@media only screen and (min-width: 768px) {
  .section__link {
    font-size: 1.4rem;
    line-height: 1.428;
    letter-spacing: 0;
  }
  .section__link .arrow {
    margin-right: 2rem;
  }
  .section__link .arrow.circle {
    height: 8rem;
    margin-right: 4.3rem;
    margin-left: 3rem;
  }
}
.section__link.main {
  position: relative;
}
.section__link.main a {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  transition: 0.5s ease;
}
@media (hover: hover) {
  .section__link.main a:hover ~ .arrow {
    transform: translateX(1rem);
  }
  .section__link.main a:hover ~ .circle::before {
    transform: translate(calc(-50% - 1rem), -50%) scale(0.75);
  }
  .section__link.main a:hover ~ .circle ~ span {
    transform: translateX(0.5rem);
  }
}
.section__link-half {
  position: relative;
  background-color: #f4f7f8;
  transition: 0.3s ease;
}
.section__link-half a {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  transition: 0.5s ease;
}
.section__link-half .arrow {
  margin-bottom: auto;
  margin-top: auto;
}
@media only screen and (min-width: 768px) {
  .section__link-half {
    padding: 3rem 6rem;
    padding-right: min(4.17vw, 6rem);
    border-radius: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
@media (hover: hover) {
  .section__link-half:hover {
    background-color: #EAF2F5;
  }
  .section__link-half:hover .arrow {
    transform: translateX(1rem);
  }
}
@media only screen and (min-width: 768px) {
  .section__link-half__text {
    font-size: 1.8rem;
    letter-spacing: 0.07em;
    line-height: 1.7777;
    margin-bottom: 1.5rem;
  }
}
.section__cta {
  position: relative;
  background-color: #daeaef;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.3s ease;
}
@media only screen and (max-width: 767px) {
  .section__cta:not(:first-child) {
    margin-top: max(3rem, 9.38vw);
  }
}
@media only screen and (min-width: 768px) {
  .section__cta:not(:first-child) {
    margin-top: 3rem;
  }
}
@media (hover: hover) {
  .section__cta:hover {
    background-color: #CDE2E8;
  }
  .section__cta:hover .arrow {
    transform: translateX(1rem);
  }
}
.section__cta.gray {
  background-color: #f4f7f8;
}
@media (hover: hover) {
  .section__cta.gray:hover {
    background-color: #EAF2F5;
  }
  .section__cta.gray:hover .arrow {
    transform: translateX(1rem);
  }
}
@media only screen and (max-width: 767px) {
  .section__cta.estimate {
    justify-content: space-between;
    border-radius: max(1rem, 3.13vw);
    padding: max(3rem, 9.38vw) max(2.6rem, 8.13vw) max(3rem, 9.38vw) max(2.6rem, 8.13vw);
    row-gap: max(1.5rem, 4.69vw);
  }
}
@media only screen and (min-width: 768px) {
  .section__cta.estimate {
    max-height: 17rem;
    border-radius: 1rem;
    padding: min(3.47vw, 5rem) min(4.17vw, 6rem) min(3.47vw, 5rem) min(4.17vw, 6rem);
    flex-direction: row;
  }
}
.section__cta.estimate .section__cta-icon {
  background: url("../images/common/estimate.svg") no-repeat;
  background-size: 100%;
  background-position: center;
}
@media only screen and (max-width: 767px) {
  .section__cta.estimate .section__cta-icon {
    width: max(4rem, 12.5vw);
    height: max(5.6rem, 17.5vw);
  }
}
@media only screen and (min-width: 768px) {
  .section__cta.estimate .section__cta-icon {
    width: 5rem;
    height: 7rem;
    margin-right: 2rem;
  }
}
@media only screen and (max-width: 767px) {
  .section__cta.question {
    justify-content: space-between;
    border-radius: max(1rem, 3.13vw);
    padding: max(1.6rem, 5vw) max(2.6rem, 8.13vw) max(3rem, 9.38vw) max(2.6rem, 8.13vw);
  }
}
@media only screen and (min-width: 768px) {
  .section__cta.question {
    border-radius: 1rem;
    padding: min(2.01vw, 2.9rem) min(4.17vw, 6rem) min(1.81vw, 2.6rem) min(4.58vw, 6.6rem);
    flex-direction: row;
  }
}
.section__cta.question .section__cta-icon {
  background: url("../images/common/question.svg") no-repeat;
  background-size: 75%;
  background-position: center;
}
@media only screen and (max-width: 767px) {
  .section__cta.question .section__cta-icon {
    background-size: 75%;
    width: max(3.1rem, 9.69vw);
    height: max(7rem, 21.88vw);
    margin-bottom: max(0.5rem, 1.56vw);
    margin-top: min(-1.56vw, -0.5rem);
  }
}
@media only screen and (min-width: 768px) {
  .section__cta.question .section__cta-icon {
    width: 4.4rem;
    height: 11.5rem;
    min-width: 4.4rem;
    min-height: 11.5rem;
    margin-right: 2rem;
  }
}
@media only screen and (max-width: 767px) {
  .section__cta.email {
    justify-content: space-between;
    border-radius: max(1rem, 3.13vw);
    padding: max(3rem, 9.38vw) max(2.6rem, 8.13vw) max(3rem, 9.38vw) max(2.6rem, 8.13vw);
    row-gap: max(1.5rem, 4.69vw);
  }
}
@media only screen and (min-width: 768px) {
  .section__cta.email {
    max-height: 17rem;
    border-radius: 1rem;
    padding: min(3.47vw, 5rem) min(4.17vw, 6rem) min(3.47vw, 5rem) min(4.17vw, 6rem);
    flex-direction: row;
  }
}
.section__cta.email .section__cta-icon {
  background: url("../images/common/email.svg") no-repeat;
  background-size: 100%;
  background-position: center;
}
@media only screen and (max-width: 767px) {
  .section__cta.email .section__cta-icon {
    width: max(5rem, 15.63vw);
    height: max(4rem, 12.5vw);
    align-self: baseline;
  }
}
@media only screen and (min-width: 768px) {
  .section__cta.email .section__cta-icon {
    width: 5rem;
    height: 7rem;
    margin-right: 2rem;
  }
}
.section__cta-icon {
  display: inline-block;
}
.section__cta-box {
  width: 100%;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 767px) {
  .section__cta-box {
    justify-content: space-between;
  }
}
@media only screen and (min-width: 768px) {
  .section__cta-box {
    max-width: 22rem;
    margin-right: min(4.17vw, 6rem);
  }
}
.section__cta-title {
  white-space: nowrap;
}
@media only screen and (max-width: 767px) {
  .section__cta-title {
    font-size: max(1.8rem, 5.63vw);
    margin-right: max(0.5rem, 1.56vw);
    line-height: 1.3888;
    letter-spacing: 0.07em;
  }
}
@media only screen and (min-width: 768px) {
  .section__cta-title {
    font-size: 2.5rem;
    letter-spacing: 0;
    line-height: 1.2;
  }
}
@media only screen and (max-width: 767px) {
  .section__cta-text {
    font-size: max(1.3rem, 4.06vw);
    line-height: 1.384;
    letter-spacing: 0.05em;
  }
}
@media only screen and (min-width: 768px) {
  .section__cta-text {
    font-size: 1.6rem;
    line-height: 1.875;
    letter-spacing: 0.03em;
    justify-self: flex-start;
    max-width: 53rem;
    margin-right: min(6.67vw, 9.6rem);
  }
}
.section__cta-link {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  transition: 0.5s ease;
}
.section__cta .arrow {
  display: block;
  align-self: center;
}
@media only screen and (max-width: 767px) {
  .section__cta .arrow {
    justify-self: flex-end;
  }
}
@media only screen and (min-width: 768px) {
  .section__cta .arrow {
    min-width: 14px;
    margin-left: auto;
  }
}
@media only screen and (max-width: 767px) {
  .section__nav {
    margin-top: max(3rem, 9.38vw);
  }
}
@media only screen and (min-width: 768px) {
  .section__nav {
    max-width: 38rem;
    margin-left: auto;
    margin-right: 0;
    padding-left: min(1.39vw, 2rem);
  }
}
.section__nav-item {
  position: relative;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #daeaef;
}
.section__nav-item:first-of-type {
  border-top: 1px solid #daeaef;
}
@media only screen and (max-width: 767px) {
  .section__nav-item {
    padding-top: max(2rem, 6.25vw);
    padding-bottom: max(2rem, 6.25vw);
    padding-left: max(1rem, 3.13vw);
  }
}
@media only screen and (min-width: 768px) {
  .section__nav-item {
    padding: 2.5rem 0 2.5rem 1.6rem;
  }
}
@media (hover: hover) {
  .section__nav-item:hover .arrow {
    transform: translateX(1rem);
  }
  .section__nav-item:hover .arrow.down {
    transform: rotate(90deg) translateX(0.5rem);
  }
}
.section__nav-item a {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  transition: 0.5s ease;
}
.section__nav-item .arrow {
  flex-shrink: 0;
  align-self: unset;
}
.section__nav-item .num {
  display: inline-block;
  width: min-content;
  white-space: nowrap;
}
@media only screen and (max-width: 767px) {
  .section__nav-item .num {
    display: none;
  }
}
@media only screen and (min-width: 768px) {
  .section__nav-item .num {
    margin-right: min(0.9vw, 1.3rem);
    margin-left: min(2.43vw, 3.5rem);
    font-size: 2rem;
    line-height: 1.15;
    letter-spacing: 0.04em;
  }
}
@media only screen and (max-width: 767px) {
  .section__nav-item p {
    margin-left: max(2rem, 6.25vw);
    font-size: max(1.4rem, 4.38vw);
    line-height: 1.428;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 768px) {
  .section__nav-item p {
    font-size: 1.8rem;
    letter-spacing: 0.07em;
    line-height: 1.7777;
    white-space: nowrap;
  }
}
@media only screen and (max-width: 1024px) {
  .section__nav-item p {
    white-space: unset;
  }
}
.section__nav-item.active {
  background-color: #f4f7f8;
  color: #8fbbc1;
}

.menu {
  cursor: pointer;
  background-color: #38a095;
}
@media only screen and (max-width: 767px) {
  .menu {
    width: 6rem;
    height: 6rem;
    padding: 1.8rem 1.5rem 2.3rem;
  }
}
@media only screen and (min-width: 768px) {
  .menu {
    padding: 3rem 2rem;
    width: 8rem;
    height: 8rem;
  }
}
@media only screen and (min-width: 83.125em) {
  .menu {
    display: none;
  }
}
.menu__wrapper {
  pointer-events: none;
  position: relative;
}
.menu span {
  pointer-events: none;
  display: block;
  width: 30px;
  height: 2px;
  background-color: #fdffff;
  position: absolute;
  left: 0;
  transition: 0.5s ease;
}
@media only screen and (min-width: 768px) {
  .menu span {
    width: 40px;
  }
}
.menu span:first-child {
  top: 0;
}
.menu span:nth-child(2) {
  top: 10px;
}
.menu span:nth-child(3) {
  top: 20px;
}
@media only screen and (max-width: 767px) {
  .menu.open span:first-child {
    transform: translate(2px, 10px) rotate(45deg);
  }
}
@media only screen and (min-width: 768px) {
  .menu.open span:first-child {
    transform: translate(0px, 10px) rotate(45deg);
  }
}
.menu.open span:nth-child(2) {
  transform: scaleX(0);
}
@media only screen and (max-width: 767px) {
  .menu.open span:nth-child(3) {
    transform: translate(2px, -10px) rotate(-45deg);
  }
}
@media only screen and (min-width: 768px) {
  .menu.open span:nth-child(3) {
    transform: translate(0px, -10px) rotate(-45deg);
  }
}

/*========= レイアウトのためのCSS ===============*/
.wave-wrapper {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
}
@media only screen and (max-width: 767px) {
  .wave-wrapper {
    top: 2%;
    height: 110px;
  }
}
@media only screen and (min-width: 768px) {
  .wave-wrapper {
    top: 9rem;
    height: 110px;
  }
}

/*========= waveを描画するエリア設定 ===============*/
.wave {
  position: relative;
}
@media only screen and (max-width: 767px) {
  .wave {
    height: 110px;
  }
}
@media only screen and (min-width: 768px) {
  .wave {
    height: 110px;
  }
}

.wave canvas {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}
@media only screen and (max-width: 767px) {
  .wave canvas {
    height: 110px;
  }
}
@media only screen and (min-width: 768px) {
  .wave canvas {
    height: 110px;
  }
}

.top {
  width: 100%;
}
.top__wrapper {
  position: relative;
  overflow: hidden;
  width: inherit;
  height: inherit;
}
@media only screen and (max-width: 767px) {
  .top__wrapper {
    height: 100vh;
    /* Fallback */
    height: calc(var(--vh, 1vh) * 100);
  }
}
@media only screen and (min-width: 768px) {
  .top__wrapper {
    padding-top: 8rem;
    height: calc(100vh - 6rem);
    height: calc((var(--vh, 1vh) * 100) - 6rem);
  }
}
.top-swiper-outer, .top-swiper, .top__image {
  width: 100%;
  height: 100%;
}
.top-swiper-outer {
  z-index: 100;
  overflow: hidden;
}
.top__image {
  overflow: hidden;
  pointer-events: none;
}
.top__image img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.top__texts {
  position: absolute;
  z-index: 200;
}
@media only screen and (max-width: 767px) {
  .top__texts {
    bottom: max(3rem, 9.38vw);
    left: max(3rem, 9.38vw);
  }
}
@media only screen and (min-width: 768px) {
  .top__texts {
    bottom: 4rem;
    left: 8rem;
  }
}
.top__texts h1 {
  font-size: max(1.8rem, 5.63vw);
  margin-bottom: max(2rem, 6.25vw);
  line-height: 1.777;
  letter-spacing: 0.07em;
}
@media only screen and (min-width: 768px) {
  .top__texts h1 {
    line-height: 1.714;
    letter-spacing: 0.05em;
    font-size: 2.8rem;
    margin-bottom: 4rem;
  }
}
.top__texts a {
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: max(1rem, 3.13vw);
  max-width: max(20rem, 62.5vw);
  padding: max(1.4rem, 4.38vw) max(7.2rem, 22.5vw) max(1.4rem, 4.38vw) max(2rem, 6.25vw);
  font-size: max(1.2rem, 3.75vw);
  letter-spacing: 0;
  line-height: 1.416;
  white-space: nowrap;
  position: relative;
  transition: 0.5s ease;
}
@media only screen and (min-width: 768px) {
  .top__texts a {
    border-radius: 1rem;
    padding: 2rem 5.4rem 2rem 3rem;
    max-width: 21rem;
    font-size: 1.4rem;
    line-height: 1.428;
  }
}
.top__texts a .arrow {
  position: absolute;
  top: max(0.5rem, 1.56vw);
  right: max(0.52rem, 1.63vw);
}
@media only screen and (min-width: 768px) {
  .top__texts a .arrow {
    top: 0.5rem;
    right: 0.52rem;
  }
}
@media (hover: hover) {
  .top__texts a:hover {
    background-color: #f4f7f8;
  }
}
.top__scroll {
  writing-mode: vertical-rl;
  position: absolute;
  z-index: 50;
  font-size: max(1.4rem, 4.38vw);
  line-height: 1.214;
  letter-spacing: 0.04em;
  bottom: 0;
  padding-bottom: max(3.7rem, 11.56vw);
  right: max(3rem, 9.38vw);
  padding-right: max(0.6rem, 1.88vw);
  border-right: 1px solid #2b3433;
}
@media only screen and (min-width: 768px) {
  .top__scroll {
    display: none;
  }
}
@media only screen and (min-width: 768px) {
  .top__news {
    height: 6rem;
    width: inherit;
    background-color: #f4f7f8;
  }
  .top__news-wrapper {
    display: flex;
    padding: 0 8rem;
    margin: 0 auto;
    width: 100%;
    justify-content: space-between;
  }
  .top__news-swiper-outer {
    padding-right: 9rem;
    overflow: hidden;
  }
  .top__news-link {
    width: 12rem;
    display: flex;
    align-items: center;
    white-space: nowrap;
    transition: 0.3s ease;
  }
}
@media only screen and (min-width: 768px) and (hover: hover) {
  .top__news-link:hover {
    opacity: 0.7;
  }
}
@media only screen and (min-width: 768px) {
  .top__news-link .arrow {
    display: inline-block;
    margin-left: 1.5rem;
    margin-top: auto;
    margin-bottom: auto;
  }
}
@media only screen and (min-width: 768px) {
  .top__news-swiper-outer, .top__news-swiper, .top__news-swiper-wrapper {
    height: 6rem;
  }
}
.top__news__item {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, max-content) 1fr;
  height: 100%;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease;
}
@media (hover: hover) {
  .top__news__item:hover {
    opacity: 0.7;
  }
}
.top__news__item a {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  transition: 0.5s ease;
}
.top__news__date {
  font-size: 1.4rem;
  line-height: 1.428;
  letter-spacing: 0;
  font-family: "Noto Sans JP", sans-serif;
  font-weight: 300;
  margin-right: 1.5rem;
  white-space: nowrap;
  min-width: 6.7rem;
}
.top__news__cat, .top__news__text {
  font-family: "Noto Sans JP", sans-serif;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1.4rem;
  letter-spacing: 0;
  line-height: 1.428;
}
@media only screen and (min-width: 768px) {
  .top__news__text {
    max-width: 88.3rem;
    width: 100%;
  }
}
.top__news__cat {
  position: relative;
  color: #38a095;
  max-width: 9.5rem;
  min-width: 6.7rem;
  margin-right: 4rem;
}
.top__news__cat::before {
  content: "#";
  color: inherit;
  padding-right: 0.2em;
}

@media only screen and (max-width: 767px) {
  .top-pagination.swiper-pagination-bullets {
    display: none;
  }
}
@media only screen and (min-width: 768px) {
  .top-pagination.swiper-pagination-bullets {
    position: absolute;
    right: 8rem;
    bottom: 4rem;
    left: auto;
    z-index: 2;
    width: max-content;
    text-align: center;
    font-size: 1.4rem;
    border-radius: 50%;
  }
  .top-pagination.swiper-pagination-bullets button, .top-pagination.swiper-pagination-bullets svg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;
  }
  .top-pagination.swiper-pagination-bullets .swiper-pagination-bullet.custom-dot {
    width: 1rem;
    height: 1rem;
    position: relative;
    background: #38a095;
    transition: all 0.5s;
    opacity: 1;
    margin-left: 0;
  }
  .top-pagination.swiper-pagination-bullets .swiper-pagination-bullet.custom-dot:not(:last-child) {
    margin-right: 1rem;
  }
  .top-pagination.swiper-pagination-bullets .swiper-pagination-bullet.custom-dot:last-child {
    margin-right: 0;
  }
  .top-pagination.swiper-pagination-bullets .swiper-pagination-bullet.custom-dot circle {
    fill: #fdffff;
    stroke: #fdffff;
    stroke-width: 4;
    stroke-dasharray: 85;
    transform-origin: 50%;
    transform: rotate(-90deg);
    transition: fill 0.5s, opacity 1.5s;
  }
  .top-pagination.swiper-pagination-bullets .swiper-pagination-bullet.custom-dot circle.circle-one {
    stroke: rgba(50, 50, 50, 0.2);
    transition: opacity 1.5s;
    opacity: 0;
  }
  .top-pagination.swiper-pagination-bullets .swiper-pagination-bullet-active.custom-dot circle {
    fill: transparent;
    stroke-dashoffset: 85;
  }
  .top-pagination.swiper-pagination-bullets .swiper-pagination-bullet-active.custom-dot circle.circle-one {
    transition: opacity 1.5s;
    opacity: 1;
  }
  .top-pagination.swiper-pagination-bullets .swiper-pagination-bullet-active.custom-dot circle.circle-two {
    animation: drawCircle 8.2s linear forwards;
  }
}

.section.product {
  background-color: #fdffff;
  padding-left: 0;
  padding-right: 0;
  position: relative;
}
@media only screen and (max-width: 767px) {
  .section.product {
    padding-top: max(10.8rem, 33.75vw);
    padding-bottom: max(5rem, 15.63vw);
  }
}
@media only screen and (min-width: 768px) {
  .section.product {
    padding-top: 6rem;
    padding-bottom: 8.8rem;
  }
}
@media only screen and (min-width: 768px) {
  .section.product .wave-wrapper {
    top: 9.5rem;
  }
}
@media only screen and (max-width: 767px) {
  .section.product .section__wrapper {
    padding-left: max(3rem, 9.38vw);
    padding-right: max(3rem, 9.38vw);
  }
}
@media only screen and (min-width: 768px) {
  .section.product .section__wrapper {
    padding-left: 8rem;
    padding-right: 8rem;
    max-width: 120rem;
  }
}
@media only screen and (min-width: 768px) {
  .section.product .section__title {
    align-self: flex-end;
  }
}
@media only screen and (max-width: 767px) {
  .section.product .section__image {
    margin-top: max(2rem, 6.25vw);
    margin-bottom: max(2rem, 6.25vw);
  }
}
@media only screen and (max-width: 767px) {
  .section.product .section__text.lg {
    margin-bottom: max(3.4rem, 10.63vw);
    padding-bottom: max(0.7rem, 2.19vw);
    padding-top: max(0.7rem, 2.19vw);
  }
}
@media only screen and (min-width: 768px) {
  .section.product .section__text.lg {
    margin-bottom: 0;
    width: 105%;
    padding: 16px 0;
  }
}
@media only screen and (max-width: 767px) {
  .section.product .section__en {
    margin-bottom: max(1.5rem, 4.69vw);
  }
}
@media only screen and (min-width: 768px) {
  .section.product .section__en {
    margin-bottom: 2rem;
  }
}
@media only screen and (min-width: 768px) {
  .section.product .section__box.first {
    margin-bottom: 6rem;
  }
}
@media only screen and (min-width: 768px) {
  .section.product .section__box.first .section__col1 {
    display: flex;
  }
}
@media only screen and (min-width: 768px) {
  .section.product .section__box.second .section__col2 {
    height: 53.4rem;
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
}
@media only screen and (min-width: 768px) {
  .section.product .section__link {
    pointer-events: none;
    grid-column: 1/span 1;
    grid-row: 3/span 1;
  }
}
@media (hover: hover) {
  .section.product .section__link:hover .arrow {
    transform: translateX(1rem);
  }
}
@media only screen and (min-width: 768px) {
  .section.product .product__circles {
    margin-top: 9.4rem;
    margin-left: 7rem;
  }
}
@media only screen and (max-width: 767px) {
  .section.product .product__circle {
    display: none;
  }
}
@media only screen and (min-width: 768px) {
  .section.product .product__circle {
    background-color: #f4f7f8;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    z-index: 5;
  }
  .section.product .product__circle.circle1 {
    width: 5rem;
    height: 5rem;
    top: 0;
    left: 15.5rem;
  }
  .section.product .product__circle.circle2 {
    width: 10rem;
    height: 10rem;
    top: 3.7rem;
    left: 20.3rem;
  }
}
@media only screen and (max-width: 767px) {
  .section.product .product__content {
    background-color: #f4f7f8;
    border-radius: max(1rem, 3.13vw);
    padding: max(3rem, 9.38vw) max(2.5rem, 7.81vw) max(3rem, 9.38vw) max(2.5rem, 7.81vw);
    position: relative;
  }
}
@media only screen and (min-width: 768px) {
  .section.product .product__content {
    position: relative;
    z-index: 10;
    width: 100%;
    max-width: 46rem;
    height: 44rem;
    display: grid;
    grid-template-columns: repeat(2, min-content);
    grid-template-rows: repeat(3, min-content);
    align-content: center;
    justify-content: center;
  }
}
.section.product .product__content picture, .section.product .product__content img {
  pointer-events: none;
}
@media only screen and (max-width: 767px) {
  .section.product .product__content picture, .section.product .product__content img {
    display: none;
  }
}
@media only screen and (min-width: 768px) {
  .section.product .product__content picture, .section.product .product__content img {
    grid-column: 2/span 1;
    grid-row: 1/span 1;
  }
}
.section.product .product__content h3 {
  pointer-events: none;
  line-height: 1.7777;
  letter-spacing: 0.07em;
}
@media only screen and (max-width: 767px) {
  .section.product .product__content h3 {
    font-size: max(1.8rem, 5.63vw);
    margin-bottom: max(0.5rem, 1.56vw);
  }
}
@media only screen and (min-width: 768px) {
  .section.product .product__content h3 {
    width: max-content;
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }
}
.section.product .product__content .product__grid-title, .section.product .product__content p {
  pointer-events: none;
}
.section.product .product__bg {
  transition: 0.3s ease;
}
@media only screen and (max-width: 767px) {
  .section.product .product__bg {
    position: absolute;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    background-color: #f4f7f8;
    width: max(2rem, 6.25vw);
    height: max(2rem, 6.25vw);
    top: min(-3.13vw, -1rem);
  }
}
@media only screen and (min-width: 768px) {
  .section.product .product__bg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: min(31.94vw, 46rem);
    height: min(30.56vw, 44rem);
    background-color: #f4f7f8;
    z-index: -1;
    border-radius: 50%;
    min-width: 362.5px;
    min-height: 346.8px;
  }
}
@media only screen and (min-width: 768px) and (hover: hover) {
  .section.product .product__bg:hover {
    transform: translate(-50%, -50%) scale(1.05);
  }
  .section.product .product__bg:hover ~ .section__link .arrow {
    transform: translateX(1rem);
  }
}
.section.product .product__bg a {
  border-radius: 50%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  transition: 0.5s ease;
}
@media only screen and (max-width: 767px) {
  .section.product .product__grid-title {
    display: contents;
  }
}
@media only screen and (min-width: 768px) {
  .section.product .product__grid-title {
    grid-column: 1/span 1;
    grid-row: 1/span 1;
    align-self: flex-end;
  }
}
@media only screen and (max-width: 767px) {
  .section.product .product__text {
    margin-bottom: max(2rem, 6.25vw);
    font-size: max(1.4rem, 4.38vw);
    line-height: 1.7857;
    letter-spacing: 0;
    text-align: justify;
  }
}
@media only screen and (min-width: 768px) {
  .section.product .product__text {
    padding: 3px 0;
    margin-bottom: 2.7rem;
    font-size: 1.4rem;
    line-height: 1.714;
    letter-spacing: 0.05em;
    grid-column: 1/span 2;
    grid-row: 2/span 1;
  }
}
.section.about {
  background-color: #f4f7f8;
  padding-left: 0;
  padding-right: 0;
  position: relative;
}
@media only screen and (max-width: 767px) {
  .section.about {
    padding-top: max(5rem, 15.63vw);
    padding-bottom: max(4rem, 12.5vw);
  }
}
@media only screen and (min-width: 768px) {
  .section.about {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }
}
.section.about .wave-wrapper {
  top: 30rem;
}
@media only screen and (max-width: 767px) {
  .section.about .wave-wrapper, .section.about .wave-wrapper .wave, .section.about .wave-wrapper canvas {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .section.about .section__wrapper {
    padding-left: max(3rem, 9.38vw);
    padding-right: max(3rem, 9.38vw);
  }
}
@media only screen and (min-width: 768px) {
  .section.about .section__wrapper {
    padding-left: 8rem;
    padding-right: 8rem;
    max-width: 120rem;
  }
}
@media only screen and (max-width: 767px) {
  .section.about .section__title {
    margin-bottom: max(2rem, 6.25vw);
  }
}
@media only screen and (max-width: 767px) {
  .section.about .section__text {
    margin-bottom: max(6rem, 18.75vw);
  }
}
@media only screen and (min-width: 768px) {
  .section.about .section__box.first {
    min-height: 18.6rem;
    margin-bottom: 9rem;
  }
}
@media only screen and (min-width: 768px) {
  .section.about .section__box.first .section__col2 {
    margin-top: 6.7rem;
    display: flex;
    justify-content: flex-end;
  }
  .section.about .section__box.first .section__col2 .section__text {
    margin-left: min(8.33vw, 12rem);
    max-width: 40rem;
  }
}
@media only screen and (min-width: 768px) {
  .section.about .section__box.second {
    margin-bottom: 6rem;
  }
}
.section.about .section__box.second .section__col1, .section.about .section__box.second .section__col2, .section.about .section__box.second .section__col3 {
  position: relative;
}
.section.about .section__box.second .section__col1 *, .section.about .section__box.second .section__col2 *, .section.about .section__box.second .section__col3 * {
  pointer-events: none;
}
.section.about .section__box.second .section__col1 > a, .section.about .section__box.second .section__col2 > a, .section.about .section__box.second .section__col3 > a {
  pointer-events: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  transition: 0.5s ease;
}
@media (hover: hover) {
  .section.about .section__box.second .section__col1 > a:hover ~ .about__image img, .section.about .section__box.second .section__col2 > a:hover ~ .about__image img, .section.about .section__box.second .section__col3 > a:hover ~ .about__image img {
    transform: scale(1.05);
  }
  .section.about .section__box.second .section__col1 > a:hover ~ .section__link .arrow, .section.about .section__box.second .section__col2 > a:hover ~ .section__link .arrow, .section.about .section__box.second .section__col3 > a:hover ~ .section__link .arrow {
    transform: translateX(1rem);
  }
  .section.about .section__box.second .section__col1 > a:hover ~ .section__link, .section.about .section__box.second .section__col2 > a:hover ~ .section__link, .section.about .section__box.second .section__col3 > a:hover ~ .section__link {
    color: #38a095;
  }
}
@media only screen and (max-width: 767px) {
  .section.about .section__box.second .section__col1 .section__link, .section.about .section__box.second .section__col2 .section__link, .section.about .section__box.second .section__col3 .section__link {
    margin-top: max(1rem, 3.13vw);
    margin-bottom: max(4rem, 12.5vw);
  }
}
@media only screen and (min-width: 768px) {
  .section.about .section__box.second .section__col1 .section__link, .section.about .section__box.second .section__col2 .section__link, .section.about .section__box.second .section__col3 .section__link {
    margin-top: 2rem;
  }
}
@media only screen and (min-width: 768px) {
  .section.about .section__box.second .section__col2 {
    margin-top: 9rem;
  }
}
@media only screen and (max-width: 767px) {
  .section.about .section__link {
    font-size: max(1.4rem, 4.38vw);
    letter-spacing: 0;
    line-height: 1.428;
  }
}
@media only screen and (min-width: 768px) {
  .section.about .section__link {
    font-size: 1.8rem;
    letter-spacing: 0.07em;
    line-height: 1.3888;
    width: unset;
  }
  .section.about .section__link .arrow {
    align-self: center;
  }
}
@media only screen and (max-width: 767px) {
  .section.about .section__link.main {
    font-size: max(1.2rem, 3.75vw);
    letter-spacing: 0;
    line-height: 1.416;
  }
}
@media only screen and (min-width: 768px) {
  .section.about .section__link.main {
    margin-left: auto;
    margin-right: auto;
    font-size: 1.4rem;
    letter-spacing: 0;
    line-height: 1.428;
    width: max-content;
  }
}
.section.about .about__image {
  position: relative;
  overflow: hidden;
}
@media only screen and (max-width: 767px) {
  .section.about .about__image img {
    transition: 0.3s ease;
    width: max(26rem, 81.25vw);
    height: max(20rem, 62.5vw);
    border-radius: max(1rem, 3.13vw);
    object-fit: cover;
    display: block;
  }
}
@media only screen and (min-width: 768px) {
  .section.about .about__image img {
    transition: 0.3s ease;
    border-radius: 1rem;
    width: 100%;
    height: auto;
    object-fit: cover;
    display: block;
  }
}
.section.about .about__nums {
  position: absolute;
  z-index: 5;
  color: #fdffff;
}
@media only screen and (max-width: 767px) {
  .section.about .about__nums {
    font-size: max(4rem, 12.5vw);
    top: max(1rem, 3.13vw);
    left: max(1rem, 3.13vw);
    line-height: 1.75;
    letter-spacing: 0.04em;
  }
}
@media only screen and (min-width: 768px) {
  .section.about .about__nums {
    font-size: 4rem;
    top: 1rem;
    left: 2rem;
  }
}

.full-image {
  width: 100%;
}
@media only screen and (max-width: 767px) {
  .full-image {
    background: url("../images/index/full-image@sp.jpg") no-repeat;
    background-size: cover;
    background-position: center;
    height: max(20rem, 62.5vw);
  }
}
@media only screen and (min-width: 768px) {
  .full-image {
    background: url("../images/index/full-image@2x.jpg") no-repeat;
    background-size: cover;
    background-position: center;
    height: 30rem;
  }
}
.full-image-section {
  width: 100%;
  position: relative;
}

.section.order {
  background-color: #fdffff;
}
@media only screen and (max-width: 767px) {
  .section.order {
    padding-top: max(4rem, 12.5vw);
    padding-bottom: max(5rem, 15.63vw);
  }
}
@media only screen and (min-width: 768px) {
  .section.order {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }
}
@media only screen and (max-width: 767px) {
  .section.order .section__title {
    margin-bottom: max(2rem, 6.25vw);
  }
}
@media only screen and (min-width: 768px) {
  .section.order .section__title {
    margin-bottom: 5rem;
  }
}
@media only screen and (max-width: 767px) {
  .section.order .section__image {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .section.order .section__text {
    margin-bottom: max(1rem, 3.13vw);
  }
}
@media only screen and (min-width: 768px) {
  .section.order .section__text {
    margin-bottom: 2rem;
  }
}
@media only screen and (max-width: 767px) {
  .section.order .section__box .section__col2 {
    margin-bottom: max(4rem, 12.5vw);
  }
}
@media only screen and (min-width: 768px) {
  .section.order .section__box .section__col2 {
    margin-bottom: 3rem;
  }
}
.section.order .order__image {
  position: relative;
}
@media only screen and (max-width: 767px) {
  .section.order .order__image img {
    width: max(26rem, 81.25vw);
    height: max(20rem, 62.5vw);
    border-radius: max(1rem, 3.13vw);
    object-fit: cover;
    display: block;
  }
}
@media only screen and (min-width: 768px) {
  .section.order .order__image img {
    border-radius: 1rem;
    width: 100%;
    height: auto;
    object-fit: cover;
    display: block;
  }
}
.section.order .order__nums {
  position: absolute;
  color: #fdffff;
}
@media only screen and (max-width: 767px) {
  .section.order .order__nums {
    font-size: max(4rem, 12.5vw);
    top: max(1rem, 3.13vw);
    left: max(1rem, 3.13vw);
    line-height: 1.75;
    letter-spacing: 0.04em;
  }
}
@media only screen and (min-width: 768px) {
  .section.order .order__nums {
    font-size: 4rem;
    top: 1rem;
    left: 2rem;
  }
}

.section.news {
  background-color: #f4f7f8;
}
@media only screen and (max-width: 767px) {
  .section.news {
    padding-top: max(4rem, 12.5vw);
    padding-bottom: max(3.4rem, 10.63vw);
    margin-bottom: max(0.6rem, 1.88vw);
  }
}
@media only screen and (min-width: 768px) {
  .section.news {
    padding-top: 9rem;
    padding-bottom: 6rem;
  }
}
@media only screen and (max-width: 767px) {
  .section.news .section__title {
    margin-bottom: max(4rem, 12.5vw);
    font-size: max(1.8rem, 5.63vw);
    line-height: 1.7777;
    letter-spacing: 0.07em;
  }
}
@media only screen and (min-width: 768px) {
  .section.news .section__title {
    margin-bottom: 3rem;
  }
}
@media only screen and (min-width: 768px) {
  .section.news .section__box {
    display: grid;
    column-gap: min(8.82vw, 12.7rem);
  }
}
@media only screen and (min-width: 768px) {
  .section.news .section__box .section__col1 {
    grid-column: 1/span 1;
    grid-row: 1/span 1;
  }
}
@media only screen and (min-width: 768px) {
  .section.news .section__box .section__col2 {
    grid-column: 2/span 1;
    grid-row: 1/span 2;
  }
}
@media only screen and (min-width: 768px) {
  .section.news .section__link.main {
    grid-column: 1/span 1;
  }
}

@media only screen and (min-width: 768px) {
  .news__wrapper {
    max-width: 104rem;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 767px) {
  .news__list {
    margin-bottom: max(2rem, 6.25vw);
  }
}
@media only screen and (min-width: 768px) {
  .news__list {
    display: grid;
    align-content: space-between;
    height: 100%;
    max-height: 21rem;
  }
}
.news__item {
  position: relative;
  transition: 0.3s ease;
  cursor: pointer;
}
@media (hover: hover) {
  .news__item:hover {
    opacity: 0.7;
  }
}
.news__item a {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  transition: 0.5s ease;
}
@media only screen and (max-width: 767px) {
  .news__item {
    position: relative;
    padding-top: max(2rem, 6.25vw);
    padding-bottom: max(2rem, 6.25vw);
    display: grid;
    grid-template-columns: min-content 1fr;
    column-gap: max(1rem, 3.13vw);
  }
  .news__item:first-child::after, .news__item::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    width: 100%;
    background-color: #daeaef;
  }
  .news__item:first-child::after {
    bottom: auto;
    top: 0;
  }
}
@media only screen and (min-width: 768px) {
  .news__item {
    display: grid;
    grid-template-columns: repeat(2, max-content) 1fr;
    height: 100%;
    align-content: space-between;
    justify-content: center;
  }
}
.news__date {
  font-family: "Noto Sans JP", sans-serif;
  font-weight: 300;
  white-space: nowrap;
}
@media only screen and (max-width: 767px) {
  .news__date {
    font-size: max(1.2rem, 3.75vw);
    letter-spacing: 0;
    line-height: 1.416;
    grid-column: 1/span 1;
  }
}
@media only screen and (min-width: 768px) {
  .news__date {
    font-size: 1.4rem;
    line-height: 1.428;
    letter-spacing: 0;
    margin-right: 2rem;
    min-width: 6.7rem;
  }
}
.news__cat, .news__text {
  font-family: "Noto Sans JP", sans-serif;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media only screen and (min-width: 768px) {
  .news__cat, .news__text {
    font-size: 1.4rem;
    line-height: 1.428;
    letter-spacing: 0;
  }
}
.news__text {
  width: 100%;
}
@media only screen and (max-width: 767px) {
  .news__text {
    font-size: max(1.4rem, 4.38vw);
    letter-spacing: 0;
    line-height: 1.428;
    padding-top: max(0.3rem, 0.94vw);
    padding-bottom: max(0.3rem, 0.94vw);
    grid-column: 1/span 2;
    grid-row: 2/span 1;
  }
}
@media only screen and (min-width: 768px) {
  .news__text {
    max-width: 52.6rem;
    justify-self: flex-end;
  }
}
.news__cat {
  position: relative;
  color: #38a095;
}
@media only screen and (max-width: 767px) {
  .news__cat {
    font-size: max(1.2rem, 3.75vw);
    letter-spacing: 0;
    line-height: 1.416;
    grid-column: 2/span 1;
  }
}
@media only screen and (min-width: 768px) {
  .news__cat {
    width: calc(5em + 4rem);
    margin-right: 2rem;
  }
}
.news__cat::before {
  content: "#";
  color: inherit;
  padding-right: 0.2em;
}

html {
  visibility: visible !important;
  opacity: 1 !important;
}
@charset "UTF-8";
* {
  box-sizing: border-box;
}
html {
  font-size: 62.5%;
  scroll-behavior: smooth;
  scroll-padding-top: 82px;
  animation: 0.8s fadeIn forwards;
  @include respond(sp) {
    scroll-padding-top: $header-height;
  }
  @include respond(tab-port) {
    scroll-padding-top: $header-height-pc;
  }
}
body {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: $font-base;
  font-weight: 400;
  font-size: 1.5rem;
  width: 100%;
  color: $color-black;
  line-height: 1.6666;
  letter-spacing: 0.02em;
  &.fixed {
    width: 100%;
    height: 100%;
    position: fixed;
  }
  &.overflow-hidden {
    overflow: hidden !important;
  }
}
.wrapper,
body {
  position: relative;
}

.wrapper {
  overflow: hidden;
}
a {
  text-decoration: none;
  color: $color-black;
  transition: 0.5s ease;
}

// safari対策
/* autoprefixer: ignore next */
_::-webkit-full-page-media,
_:future,
:root html {
  min-height: -webkit-fill-available;
}
_::-webkit-full-page-media,
_:future,
:root body {
  min-height: -webkit-fill-available;
  min-height: 100vh;
}

@charset "UTF-8";
// COLORS
$color-primary: #38a095;   
$color-primary-light: #8fbbc1;

$color-secondary: #daeaef;

$color-red: #ea8472;

$color-white: #f4f7f8;
$color-menu-white: #fdffff;
$color-black: #2b3433;

$color-gray: #707070;

$color-hover-gray: #EAF2F5;
$color-hover-primary: #CDE2E8;

// FONT
$default-font-size: 1.6rem;


// FONT FAMILY
$font-base: 'Bellefair', 'Noto Sans JP', sans-serif;
$font-noto: 'Noto Sans JP', sans-serif;

// cubic-bezier
$ease-out: cubic-bezier(0.170, 0.935, 0.305, 1.000);

// CONTAINER
// padding両サイド8rem, width128rem
$container-width: 144rem;
$content-width: 98.1rem;
$padding: 8rem;

// HEADER HEIGHT
$header-height: 6rem;
$top-news-height: 6rem;
$header-height-pc: 8rem;


// CANVAS SIZE
$big-pc: 1920;
$pc-basis: 1440;
$sp-basis: 320;
$small-phone-basis: 320;
@charset "UTF-8";
.footer__middle {
  @include respond(sp) {
    display: none;
  }

  @include respond(tab-port) {
    width: 100%;
    height: 33rem;
    display: flex;

    &-col {
      position: relative;
      width: 100%;
      padding: 5rem 0 5rem 8rem;
      @include respond(hover) {
        &:hover {
          & .footer__middle-image img {
            transform: scale(1.05);
          }
          & .section__link.main {
            & .arrow {
              transform: translateX(1rem);
            }
            & .circle::before {
              transform: translate(calc(-50% - 1rem), -50%) scale(0.75);
            }
            & .arrow.white {
              transform: translate(1rem, -1rem);
            }
            & .white.circle::before {
              transform: translate(calc(-50% - 1rem), calc(-50% + 1rem)) scale(0.75);
            }
            & .circle ~ span {
              transform: translateX(0.5rem);
            }
          }
        }
      }
      &.left {
        & * {
          color: $color-menu-white;
        }
        & .section__en.title::before {
          background-color: $color-menu-white;
        }
        & .arrow::before {
          border: 1px solid $color-menu-white;
        }
        & .arrow {
        }
      }
      &.right {
        & * {
          color: $color-black;
        }
        & .section__en.title::before {
          background-color: $color-black;
        }
      }
    }
    &-image {
      overflow: hidden;
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
        transition: 0.3s ease;
      }
    }
  }
  &-link {
    @include absolute(10);
  }

  & .section__title {
    padding-bottom: 0;
    margin-bottom: 2rem;
  }
  & .section__link.main {
    margin-left: 0.3rem;
  }
}
